import React from "react";


function Modal2({ setOpenModal }) {
  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="titleCloseBtn">
          <button
            onClick={() => {
              setOpenModal(false);
            }}
          >
            X
          </button>
        </div>
        
        <div className="">
        <div className="product_img" style={{textAlign: "center"}}>  
                        <img src="assets/images/homecard/2.png"  alt="product" height={'250px'}/>
                        
                    </div>
                    <br></br>
          <p> Our endeavor is to infuse the most stringent quality control as a part of our processes to ensure best quality of our products to create a niche market for our farmers and, thereby, effecting value addition in terms of price realization for those who lack wherewithal to standardize and market their produce</p>
        </div>
       
      </div>
    </div>
  );
}

export default Modal2;
