import React, { Component } from 'react';
import moment from 'moment';
import emailjs from '@emailjs/browser';


class ResearchForm extends Component {
  constructor(props) {
    super(props);
    this.initialState = {
      checkedFacilityItems: new Map(),
      isShowOtherOptionTrue: false,
      otherFacilities: '',
      facilities: [
        { id: 1, value: "Room" },
        { id: 2, value: "Mess Facility" },
        { id: 3, value: "Conveyance" },
        { id: 4, value: "Local Guide" },
        { id: 5, value: "Any other" },
      ],
      message: '',
      name: '',
      email: '',
      phone: '',
      institute: '',
      research: '',
      topic: '',
      fromDate: '',
      toDate: '',
      name_err: '',
      email_err: '',
      phone_err: '',
      institute_err: '',
      research_err: '',
      topic_err: '',
      from_err: '',
      to_err: '',
      facilities_err: '',
      return_msg: '',
      flag: false
    }
    this.state = this.initialState
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeNumber = this.handleChangeNumber.bind(this);
    this.handleChangeInstitute = this.handleChangeInstitute.bind(this);
    this.handleChangeResearch = this.handleChangeResearch.bind(this);
    this.handleChangeTopic = this.handleChangeTopic.bind(this);
    this.handleChangeFromDate = this.handleChangeFromDate.bind(this);
    this.handleChangeToDate = this.handleChangeToDate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
    this.handleChangeotherFacilities = this.handleChangeotherFacilities.bind(this);
  }

  handleCheckBoxChange(e) {
    var isChecked = e.target.checked;
    var item = e.target.value;

    this.setState(prevState => ({ checkedFacilityItems: prevState.checkedFacilityItems.set(item, isChecked) }));

    if (item === "Any other") {
      this.setState({ isShowOtherOptionTrue: !this.state.isShowOtherOptionTrue });
    }
  }

  handleChangeotherFacilities(e) {
    this.setState({ otherFacilities: e.target.value });
  }

  handleChangeEmail(e) {
    this.setState({ email: e.target.value });
    var EmailReg = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;
    if (e.target.value === '')
      this.setState({ email_err: 'Email is Required' });
    else if (EmailReg.test(e.target.value))
      this.setState({ email_err: '' });
    else
      this.setState({ email_err: 'Enter Valid Email' });
  }

  handleChangeName(e) {
    this.setState({ name: e.target.value });
    if (e.target.value === '')
      this.setState({ name_err: 'Name is Required' });
    else
      this.setState({ name_err: '' });
  }

  handleChangeNumber(e) {
    var phoneNoReg = /^([0|\+[0-9]{1,5})?([6-9][0-9]{9})$/;
    this.setState({ phone: e.target.value });
    if (e.target.value === '')
      this.setState({ phone_err: 'Phone Number is Required' });
    else if (phoneNoReg.test(e.target.value))
      this.setState({ phone_err: '' });
    else
      this.setState({ phone_err: 'Enter valid Phone number' });
  }

  handleChangeInstitute(e) {
    this.setState({ institute: e.target.value });
    if (e.target.value === '')
      this.setState({ institute_err: 'institute is required' });
    else
      this.setState({ institute_err: '' });
  }

  handleChangeResearch(e) {
    this.setState({ research: e.target.value });
    if (e.target.value === '')
      this.setState({ research_err: 'Reasearch Area is Required' });
    else
      this.setState({ research_err: '' });
  }

  handleChangeTopic(e) {
    this.setState({ topic: e.target.value });
    if (e.target.value === '')
      this.setState({ topic_err: 'Research Topic is Required' });
    else
      this.setState({ topic_err: '' });
  }

  handleChangeFromDate(e) {
    this.setState({ fromDate: e.target.value });
    const selectedFromDate = new Date(e.target.value);
    const maxDate = new Date();
    maxDate.setHours(0, 0, 0, 0);
    maxDate.setDate(maxDate.getDate() + 1);
    if (e.target.value === '')
      this.setState({ from_err: 'From date is Required' });
    if (selectedFromDate < maxDate)
      this.setState({ from_err: 'Date must be from future. Please select valid Date' });
    else
      this.setState({ from_err: '' });
  }

  handleChangeToDate(e) {
    this.setState({ toDate: e.target.value });
    if (e.target.value === '')
      this.setState({ to_err: 'To date is Required' });
    else
      this.setState({ to_err: '' });
  }

  handleSubmit() {
    if (this.state.name === '')
      this.setState({ name_err: 'Name is Required' });
    if (this.state.email === '')
      this.setState({ email_err: 'Email is Required' });
    if (this.state.phone === '')
      this.setState({ phone_err: 'Phone Number is Required' });
    if (this.state.institute === '')
      this.setState({ institute_err: 'institute is required' });
    if (this.state.research === '')
      this.setState({ research_err: 'Reasearch Area is Required' });
    if (this.state.topic === '')
      this.setState({ topic_err: 'Research Topic is Required' });
    if (this.state.fromDate === '')
      this.setState({ from_err: 'From date is Required' });
    if (this.state.toDate === '')
      this.setState({ to_err: 'To date is Required' });

    if (this.state.name === '' || this.state.email === '' || this.state.phone === '' || this.state.institute === '' || this.state.research === '' || this.state.topic === '' || this.state.fromDate === '' || this.state.toDate === '') {
      this.setState({ return_msg: 'Please Fill all required fileds', flag: true });
    } else {
      var namev = this.state.name;
      var emailv = this.state.email;
      var phonev = this.state.phone;
      var institutev = this.state.institute;
      var resareav = this.state.research;
      var restopicv = this.state.topic;
      var fromDatev = this.state.fromDate;
      var toDatev = this.state.toDate;
      var templateParams = {
        //name: namev,
        //email: emailv,
        //phone: phonev,
        //eduinstitute: institutev,
        //researcharea: resareav,
        //researchtopic: restopicv,
        //tentativestartdate: fromDatev,
        //tentativeenddate: toDatev,            
        message: "Research Interest - name: " + namev + " email: " + emailv + " phone: " + phonev + " edu-institute: " + institutev + " research-area" + resareav + " research-topic" + restopicv + " tentative-start-date" + fromDatev + " tentative-end-date" + toDatev
      };
      //emailjs.init("9hrUjU18skAfBg82l");
      emailjs.send('service_ey2109b', 'template_po50f6k', templateParams, '9hrUjU18skAfBg82l')
      // we must remove facilities with ischecked false & error properties of formState here for integration. 
      console.log(this.state)
      //this.setState({return_msg: 'Success.', flag: true});
      this.setState(this.initialState);
      const checkboxes = document.getElementsByClassName("checkboxOperation")
      for (let i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
      }
      this.setState({return_msg: 'Thanks for your interest. Let us get back to you.', flag: true});
    }
  }

  render() {

    return (
      <section className="contact-section">
        <div className="container">
          <div className="contact_wrp">
            <div className="row">
              <div className="col-md-12 col-sm-12 inner-contact">
                <div className="base-header base_header_left">
                  <small>Join us for Research</small>
                  <h3>Submit Request</h3>
                </div>
                <div className="contact-form">
                  {/*<div id="message">
                    {this.state.flag ?
                      <div className="alert alert-success">
                        <strong>{this.state.return_msg}</strong>
                      </div>
                      : null}
                    </div>*/}
                  <form method="post" name="rcontact-form" id="contact-form">
                    <div className="row">
                      <div className="col-lg-6 col-sm-12">
                        <span className="error" id="err">{this.state.name_err}</span>
                        <input type="text" id="name" value={this.state.name} onChange={this.handleChangeName} className="con-field form-control" placeholder="Name" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-sm-12">
                        <span className="error" id="err">{this.state.email_err}</span>
                        <input type="text" value={this.state.email} onChange={this.handleChangeEmail} className="con-field form-control" id="email" placeholder="Email" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-sm-12">
                        <span className="error" id="err">{this.state.phone_err}</span>
                        <input id="phone" type="text" className="con-field form-control" pattern="[1-9]{1}[0-9]{9}" placeholder="Enter phone number" value={this.state.phone} onChange={this.handleChangeNumber} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-sm-12">
                        <span className="error" id="err">{this.state.institute_err}</span>
                        <input type="text" id="institute" value={this.state.institute} onChange={this.handleChangeInstitute} className="con-field form-control" placeholder="Name of Institution" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-sm-12">
                        <span className="error" id="err">{this.state.research_err}</span>
                        <input type="text" id="research" value={this.state.research} onChange={this.handleChangeResearch} className="con-field form-control" placeholder="Research Area" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-sm-12">
                        <span className="error" id="err">{this.state.topic_err}</span>
                        <input type="text" id="topic" value={this.state.topic} onChange={this.handleChangeTopic} className="con-field form-control" placeholder="Topic" />
                      </div>
                    </div>
                    <div className="row">
                      <label className="col-lg-6 col-sm-12">
                        Choose stay Period:
                      </label>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-sm-12">
                        <label className="col-lg-6 col-sm-12">From :</label>
                        <span className="error" id="err">{this.state.from_err}</span>
                        <input type="date" id="fromDate" value={this.state.fromDate} onChange={this.handleChangeFromDate} className="con-field form-control" min={moment().format("YYYY-MM-DD")} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-sm-12">
                        <label className="col-lg-6 col-sm-12">To : </label>
                        <span className="error" id="err">{this.state.to_err}</span>
                        <input type="date" id="toDate" value={this.state.toDate} onChange={this.handleChangeToDate} className="con-field form-control" min={this.state.fromDate} />
                      </div>
                    </div>

                    <div className="row">
                      <label className="col-lg-6 col-sm-12">Choose Facilities Required</label>
                    </div>

                    <div className="row">
                      <div className="margin-right">
                        <div >
                          {this.state.facilities.map(item => (
                            <div key={item.id}>
                              <input className='checkboxOperation' type="checkbox" id={item.id} name="item" value={item.value} onChange={this.handleCheckBoxChange} /> {item.value}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    {
                      this.state.isShowOtherOptionTrue ?
                        <div className="row">
                          <div className="col-lg-6 col-sm-12">
                            <input type="text" id="otherFacilities" value={this.state.otherFacilities} onChange={this.handleChangeotherFacilities} className="con-field form-control" placeholder="Please specify required facility" />
                          </div>
                        </div>
                        : null
                    }
                    <div className="submit-area">
                      <input type="button" id="submit" name="send" onClick={this.handleSubmit} className="submit-contact submitBnt" value="Submit" />
                      <div id="simple-msg"></div>
                    </div>
                    <div id="message">
                      {this.state.flag ?
                        <div className="alert alert-success">
                          <strong>{this.state.return_msg}</strong>
                        </div>
                        : null}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div >
      </section >

    )
  }
}


export default ResearchForm;