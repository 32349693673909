import React from "react"; 

const PartnershipContent = () => {
  return (
 
	<section className="">
        <div className="container"> 
            <div className="row">
            {/*    <div className="col-md-6 col-sm-12">
                    <div className="about_img"> 
                        <img src="assets/images/about.jpg" alt="about" /> 
                    </div>
            </div> */}
                <div className="col-md-12 col-sm-12">
                    <div className="about_item_tb">
                        <div className="about_item_tbcell">
                             <div className="base-header base_header_left">
                                <small>Gramiigo</small>
                                <h3>Partner with us</h3>
                             </div>
                            <div className="about_item">
                              {/*  <h4>Natural Products</h4> */}
                                 <p>GRAMiigo invites corporate entities, NGOs, and Institutions working in the rural sector of India 
                                    to explore possibilities of collaboration in the following areas: -
                                    <ol>
                                        <li> Manufacturing, processing, and value addition in the agri sector</li>
                                        <li> Skills development & Training</li>
                                        <li>Health, Education, and Nutrition</li>
                                        <li> Community Services</li>
                                        <li> Women Empowerment</li>
                                        <li> Environment Protection</li>
                                        <li>Precision Farming</li>
                                        <li>Waste Management</li>
                                        <li>Rainwater Harvesting</li>
                                        <li>Research and development in agriculture & allied sector</li>
                                        
                                    </ol>
                                    Send your interest to…..contact@gramiigo.org
                                    </p>
                            </div>
                            
                        </div>
                    </div>
                </div>
                {/*about_wrp*/}
            </div>
            {/* row */}
        </div>
        {/* container */}
    </section>


  );
};

export default PartnershipContent;
