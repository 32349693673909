import React,{Component, Fragment} from 'react'; 
import ServiceSidebar from '../../components/ServiceSidebar'; 
import MetaTags from "react-meta-tags";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../components/Breadcrumb";


class InitiativesGrammiigoSeeds extends Component{
	render(){

	const serviceTopArray = [
		{
			image:'1.jpg',
            title:"GRAMiigo Seeds",
			desPartOne:"As the role of high-yield hybrid varieties of seeds got prominence in the Indian agriculture space,the sale of fake seeds became an issue that has aggravated the vulnerability of Indian farmers in conditions in which they find it difficult to break the cycle of indebtedness; the situation becomes particularly insurmountable for small and marginal farmers who already face unfavourable market conditions owing to the small scale and size of their operations. When some estimates suggest the penetration of fake seeds up to 25% of the total market, it becomes necessary to intervene in this domain as the first step towards creating an ecosystem for a complete end-to-end value chain to support small and marginal farmers to make it sustainable in real sense.",
            desPartTwo:"Gramiigo has started with the potato seed project as a part of the development of a pre-farm support mechanism for potato farmers to make quality potato seeds available to them. The project is based on aeroponics technology which is the most modern technique being used for potato seed production; production from the first unit being developed in the potato belt of Agra (Uttar Pradesh) is likely to come out latest by the end of the year 2022.", 
		},
	]
 
    const serviceBottomArray = [
        {
            icon:'check.png',
            title:"100% Satisfaction",
            des:"Experienced staff read nal Experienced sto help you full Prond Experienced eaelp you help anytime you",  
        },
        {
            icon:'check.png',
            title:"Professional Staff",
            des:"Experienced staff read nal Experienced sto help you full Prond Experienced eaelp you help anytime you",  
        },
    ]

    const serviceToptMap = serviceTopArray.map((val, i) => {
        return(
		    <div className="single_service_left" key={i}> 
                <img src={`assets/images/initiative/${val.image}`} alt="" />
                <h4>{val.title}</h4>
                <p>{val.desPartOne}</p>
                <p>{val.desPartTwo}</p>
            </div>
        )
    });

    const serviceBottomtMap = serviceBottomArray.map((val, i) => {
        return(
            <div className="col-md-6 col-sm-12" key={i}>
                <div className="sing_service_item">
                    <div className="icon-serv"> 
                        <img src={`assets/images/${val.icon}`} alt="" />
                    </div>
                    <h4>{val.title}</h4>
                    <p>{val.des}</p>
                </div>
            </div>
        )
    });


	return(
        <Fragment>
        <MetaTags>
          <title>Gramiigo | Gramiigo Seeds</title>
          <meta
            name="description"
            content="Gramiigo"
          />
        </MetaTags>
        <LayoutOne>

       <div className="single-services-page">

            {/*====================  breadcrumb area ====================*/}

            <Breadcrumb title="GRAMiigo Seeds" />
            
            {/*====================  End of breadcrumb area  ====================*/} 


            {/*====================  Single Services area ====================*/}
            <div className="single_service">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-sm-12">

                            {serviceToptMap}

                            
                        </div>

                        <div className="col-md-4 col-sm-12">

                            <ServiceSidebar />
                            
                        </div>

                    </div>
                </div>
            </div>
             {/*====================  End Single Services area ====================*/} 
        </div>
        
          </LayoutOne>
        </Fragment>

 	)

	}
}

 
export default InitiativesGrammiigoSeeds;