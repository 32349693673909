import React from "react";


function Modal({ setOpenModal }) {
  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="titleCloseBtn">
          <button
            onClick={() => {
              setOpenModal(false);
            }}
          >
            X
          </button>
        </div>
        
        <div className="">
        <div className="product_img" style={{textAlign: "center"}}>  
                        <img src="assets/images/cards/1.png"  alt="product" />
                        
                    </div>
          <p> Our network of core farmers and our dedicated farms combine with our agri value chain for forward and backward integration to produce the most authentic organic under our stringent regulatory mechanism </p>
        </div>
{/*
        <div className="footer">
          <button
            onClick={() => {
              setOpenModal(false);
            }}
            id="cancelBtn"
          >
            Cancel
          </button>
          
        </div>
          */}
      </div>
    </div>
  );
}

export default Modal;
