import React,{Component, Fragment} from 'react'; 
import ServiceSidebar from '../../components/ServiceSidebar'; 
import MetaTags from "react-meta-tags";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../components/Breadcrumb";


class InitiativesGrammiigoCare extends Component{
	render(){

	const serviceTopArray = [
		{
			image:'6.jpg',
            title:"GRAMiigo Care",
			desPartOne:"‘Gramiigo Care’ is a comprehensive socioeconomic initiative by Gramiigo Foundation which seeks to create opportunities and conditions for inclusive growth without disturbing the delicate balance between nature and development. In combination with professionally managed interventions in the agriculture sector, Gramiigo Care fills the gaps which are left during the developmental journey of any society. While health and education are the focus areas to create a robust foundation for human development, the activities to rebuild and reclaim the lost space for the flora and fauna",
            desPartTwo:"Gramiigo has started with the potato seed project as a part of the development of a pre-farm support mechanism for potato farmers to make quality potato seeds available to them. The project is based on aeroponics technology which is the most modern technique being used for potato seed production; production from the first unit being developed in the potato belt of Agra (Uttar Pradesh) is likely to come out latest by the end of the year 2022.", 
		},
	]
 
    const serviceBottomArray = [
        {
            icon:'check.png',
            title:"100% Satisfaction",
            des:"Experienced staff read nal Experienced sto help you full Prond Experienced eaelp you help anytime you",  
        },
        {
            icon:'check.png',
            title:"Experienced Staff",
            des:"Experienced staff read nal Experienced sto help you full Prond Experienced eaelp you help anytime you",  
        },
    ]

    const serviceToptMap = serviceTopArray.map((val, i) => {
        return(
		    <div className="single_service_left" key={i}> 
                <img src={`assets/images/initiative/${val.image}`} alt="" />
                <h4>{val.title}</h4>
                <p>{val.desPartOne}</p>
                
            </div>
        )
    });

    const serviceBottomtMap = serviceBottomArray.map((val, i) => {
        return(
            <div className="col-md-6 col-sm-12" key={i}>
                <div className="sing_service_item">
                    <div className="icon-serv"> 
                        <img src={`assets/images/${val.icon}`} alt="" />
                    </div>
                    <h4>{val.title}</h4>
                    <p>{val.des}</p>
                </div>
            </div>
        )
    });


	return(
        <Fragment>
        <MetaTags>
          <title>Gramiigo | Gramiigo Care</title>
          <meta
            name="description"
            content="Gramiigo"
          />
        </MetaTags>
        <LayoutOne>

       <div className="single-services-page">

            {/*====================  breadcrumb area ====================*/}

            <Breadcrumb title="GRAMiigo Care" />
            
            {/*====================  End of breadcrumb area  ====================*/} 


            {/*====================  Single Services area ====================*/}
            <div className="single_service">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-sm-12">

                            {serviceToptMap}

                            
                        </div>

                        <div className="col-md-4 col-sm-12">

                            <ServiceSidebar />
                            
                        </div>

                    </div>
                </div>
            </div>
             {/*====================  End Single Services area ====================*/} 
        </div>
        
          </LayoutOne>
        </Fragment>

 	)

	}
}

 
export default InitiativesGrammiigoCare;