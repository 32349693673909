import React, {Component} from 'react';
import {Link} from 'react-router-dom';   
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css'

class Slides extends Component{

    render(){

        let slideImages = [
            {
                img:'1.jpg',
                smallTitle: 'Professional Cleaning Services',
                title:"Grammigo",
				description:<p align="left"><h4><b>GRAMiigo–Group for Rural and Agri Management to Ignite Inclusive Growth Opportunities</b></h4></p>,
            },
            {
                img:'2_1.jpg',
                smallTitle:"Professional Cleaning Services",
                title:"Grammigo",
				description:<p align="left"><h4><b>Empowering Rural India-- Empowering the most marginalized section of society through interventions that are aimed at accelerating growth and leveraging the potential of rural economy.</b></h4></p>,
            },
			{
                img:'3.jpg',
                smallTitle:"Professional Cleaning Services",
                title:"Grammigo",
				description:<p align="left"><h4><b>A mission to develop an end-to-end value chain for all Agri commodities and crops while creating conditions for adoption of sustainable practices by farmers to achieve long-term food security and sustainable growth</b></h4></p>,
            },
        ];

		const properties = {
			slidesPerView : 1,
            loop: true,
            speed: 1000,
            watchSlidesVisibility: true,
			effect: 'slide',
			pagination: {
				el: '.swiper-pagination',
				type: 'bullets',
				clickable: true
			},
            navigation: {
                nextEl: '.st-swiper-button-next',
                prevEl: '.st-swiper-button-prev'
            },
            renderPrevButton: () => (
                <div className="st-swiper-button-prev st-swiper-button-nav d-none d-xl-block"><i className="icon-glyph-205" /></div>
              ),
              renderNextButton: () => (
                <div className="st-swiper-button-next st-swiper-button-nav d-none d-xl-block"><i className="icon-glyph-204" /></div>
              ),
            autoplay: {
                delay: 5000,
            }
		}
	 
        let ImageGalleryDataList = slideImages.map((val, i) => {
            return(
                <div >
	            <div className="single_slider slide_bg_1" style={{'backgroundImage': `url(assets/images/slider/${val.img})`}} key={i}> 
		           <div className="slider_item_tb">
			            <div className="slider_item_tbcell">
				            <div className="container">
					            <div className="row">
						            <div className="col-lg-6 col-sm-6 col-md-6
                                    ">
									  
						              
						              <p style={{'fontFamily': `'Karla', sans-serif`,'fontWeight':`normal`}}>{val.description}</p>
						              
						            </div>
					            </div>
				            </div>
			            </div>
		            </div>
		        </div>
            </div>
            )
        });

	    return ( 
	    <div className="slides_wrapper">
			<div className="slider_home">
		        <Swiper {...properties}>

		            {ImageGalleryDataList}

		        </Swiper>
			</div>
	    </div> 
	    );
	    
	}
}

export default Slides;


