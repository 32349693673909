import React from "react"; 

const AboutContent = () => {
  return (
 
	<section className="">
        <div className="container"> 
            <div className="row">
                <div className="col-md-6 col-sm-12">
                    <div className="about_img"> 
                    <br></br>
                        <img src="assets/images/aboutu.jpg" alt="about" /> 
                    </div>
                </div>
                <div className="col-md-6 col-sm-12">
                    <div className="about_item_tb">
                        <div className="about_item_tbcell">
                             <div className="base-header base_header_left">
                                 
                                <small>Gramiigo</small>
                                <h3>About Us</h3>
                             </div>
                            <div className="">
                               {/* <h4>Natural Products</h4> */}
                                 <p>GRAMIIGO (Group for Rural and Agri Management to Ignite Inclusive Growth Opportunities)
is conceived, supported, mentored, and driven by a group of professionals who, having climbed
the ladder of success in their respective fields, nurture a desire to give back to society. Backed by
years of experience and research work GRAMiigo seeks to build a self-sustainable model to
empower the most marginalized section of society through interventions that are aimed at
accelerating the growth and leveraging the potential of the rural economy in alleviating poverty
and achieving developmental goals in India.</p>
                            </div>
                            
                        </div>
                    </div>
                </div>
                
                {/*about_wrp*/}
            </div>


            <br></br>

            <div className="row">
                <div className="col-md-12 col-sm-12">
                    
                </div>
                <div className="col-md-12 col-sm-12">
                    <div className="about_item_tb">
                        <div className="about_item_tbcell">
                             <div id='whatwedo' className="base-header base_header_left">
                                 
                                
                                <h3>What we do ?</h3>
                             </div>
                            <div className="">
                               {/* <h4>Natural Products</h4> */}
                                 <p>Development of an integrated ecosystem including the integration of markets with a 360-degree
approach along the journey of an agriculture commodity from farm to fork with an in-built
mechanism for streamlining and leveraging ‘production to consumption’ lifecycle of all Agri
commodities through the application of modern farm practices and management techniques is the
long-term solution to the problems ailing the Indian agriculture sector, and thereby, economic
integration of the most marginalized section of the society.
GRAMiigo has embarked upon the mission to develop an end-to-end value chain for all Agri
commodities and crops while creating conditions for the adoption of sustainable practices by
farmers to achieve long-term food security and sustainable growth for the most marginalised
sections of the society in India. Development of Gramiigo hubs in different agriculture zones in
India with an aim to integrate an array of socioeconomic initiatives into mainstream agriecosystem to develop an umbrella of a wholesome support system at pan India level for a
sustainable future

</p>
                            </div>
                            
                        </div>
                    </div>
                </div>
                
                {/*about_wrp*/}
            </div>
            <br></br>

            <div className="row">
                <div className="col-md-12 col-sm-12">
                    
                </div>
                <div className="col-md-12 col-sm-12">
                    <div className="about_item_tb">
                        <div className="about_item_tbcell">
                             <div id = 'ruralriddle' className="base-header base_header_left">
                                 
                                
                                <h3>The Challenge to solve ‘The Rural Riddle’
</h3>
                             </div>
                            <div className="">
                               {/* <h4>Natural Products</h4> */}
                                 <p>Rural distress has been the buzzword around which many policy narratives have been created
and debated to make way for interventions in the rural sector with mixed results. While we have
been able to make considerable progress on some of the economic parameters after
independence, we still have to deal with news of farmers’ suicides and starvation deaths that
reflect the enormity of the disconnect that exists just under the veneer of the information
revolution and economic growth. Economic integration of a large swathe of population has
always been a challenge for a system that fights with its functional deficiencies at the ground
level.
Agriculture, despite being the mainstay of the Indian rural sector, has not been able to sustain the
growth trajectory attained during the period of ‘Green Revolution’ in India. While this sector
faces a multitude of problems, the absence of an integrated model to address the issues of
sustainability, scale, market connect, storage & processing, optimum price realization,
availability of quality inputs, in combination with lack of quality healthcare and education
infrastructure, has been something which stands out when we try to analyse the distress like
situations in the rural sector.Interventions at all levels of farming value chain have failed to show
the desired results for the lack of an integrated approach and efficient management at the ground
level; farmers, public and private institutions, industry, all work in silos with minimum direct
linkages which leaves little space for a planned, coordinated approach to create shared value in
an ecosystem that encourages innovation, entrepreneurial culture, and benefits of scale.
The rural sector is still largely unexplored and therefore offers numerous opportunities to
entrepreneurs and enterprises to tap. The renewed focus of the central government on the rural
sector in general and the agriculture sector in particular, has invited the attention of entrepreneurs
and the corporate world to invest in the rural sector. But these investments have not translated
into any significant improvement to the lives of the majority of the rural households—the bottom
of pyramid which fails to participate in or derive benefits from the technological interventions
offered by the startups, or from the scale of operations of corporate players.
Another interesting phenomenon has been the course-correction under business pressures by
numerous "Agri" startups, which begin with the stated cause of uplifting the marginal farmers,
but soon are forced to change to either a B2B model or limit themselves to the more profitable
areas of the rural economy, which again, are beyond the reach of average rural households. As a
result, most of the benefits still evade the real movers of the rural economy - the marginal
farmers and their households - who incidentally form the largest population base, which drives a
significant part of the aggregate consumption in the GDP of our country. The problem with all
these efforts is not the lack of the right intention or approach but there is somehow a deep
invisible disconnect and lack of understanding of the peculiarities of the rural economy and its
complications; the inability to look beyond the apparent makes things more complex and the
challenge more formidable. Therefore, it’s no surprise that most of the rural sector remains
unorganized and opaque.</p>
                            </div>
                            
                        </div>
                    </div>
                </div>
                
                {/*about_wrp*/}
            </div>

            <br></br>
            <div className="row">
                <div className="col-md-12 col-sm-12">
                    
                </div>
                
                
                {/*about_wrp*/}
            </div>


            <br></br>

            <div className="row">
                <div className="col-md-12 col-sm-12">
                    
                </div>
               
                
                {/*about_wrp*/}
            </div>
            {/* row */}
        </div>
        {/* container */}
    </section>


  );
};

export default AboutContent;

{/*  situations in the agriculture sector. GRAMiigo has embarked upon the mission to develop an end-to-end value chain for all Agri commodities and crops while creating conditions for the adoption of sustainable practices by farmers to achieve long-term food security and sustainable growth for the most marginalised sections of the society in India. As a first step towards creating such an ecosystem in the agriculture sector, we have started working on development of Gramiigo hubs in different agriculture zones in India with an aim to integrate an array of socioeconomic initiatives into mainstream agri-ecosystem to develop an umbrella of a wholesome support system at pan India level. */}