import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";
import Slides from '../components/Slides';
import AboutContent from '../components/AboutContent';
import ServiceContent from '../components/ServiceContent';
import Funfact from '../components/Funfact';
import WorkProcess from '../components/WorkProcess';
import ChooseUs from '../components/ChooseUs';

import ProductsCard from '../components/ProductCard';
import PricingList from '../components/PricingList';
import GalleryContent from '../components/GalleryContent';
import Team from '../components/Team';
import Testimonial from '../components/Testimonial';
import BlogList from '../components/BlogList';
import ClinetCarousel from '../components/ClinetCarousel';
import ContactForm from '../components/ContactForm';
import ReactReadMoreReadLess from "react-read-more-read-less";
import CardsHome from "../components/Cardshome";
import { HashLink } from 'react-router-hash-link';
var myLongText = 'Rural distress has been the buzzword around which many policy narratives have been created level.'
const Home = () => {
    return (
        <Fragment>
            <MetaTags>
                <title>Grammiigo | Home</title>
                <meta
                    name="description"
                    content="Organic Food React JS Template."
                />
            </MetaTags>
            <LayoutOne>

                <Slides />




                {/*==============================intro=====================================================================================================*/}

                <section className="about-section" style={{ backgroundImage : "url(/assets/images/back.png)"}}>
                    <div className="container" style={{ textAlign: "justify" }}>
                        <div className="row" >
                            
                            <div className="col-md-12 col-sm-12">
                                <div className="about_item_tb">
                                    <div className="about_item_tbcell">
                                        
                                        <div className="">
                                        <p>GRAMIIGO (Group for Rural and Agri Management to Ignite Inclusive Growth Opportunities)
is conceived, supported, mentored, and driven by a group of professionals who, having climbed
the ladder of success in their respective fields, nurture a desire to give back to society. Backed by
years of experience and research work GRAMiigo seeks to build a self-sustainable model to
empower the most marginalized section of society through interventions that are aimed at
accelerating the growth and leveraging the potential of the rural economy in alleviating poverty
and achieving developmental goals in India.</p>
                                            
                                        </div>

                                    </div>
                                </div>
                            </div>

                            {/*about_wrp*/}
                        </div>
                        {/* row */}
                    </div>
                    {/* container */}
                </section>

 {/* ================================================================================================================================= */}
                
                
                <ProductsCard />
                {/* ================================================================================================================================= */}
                
                
                {/* <ChooseUs/> */}

                


                <section className="about-section" style={{ textAlign: "justify" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                                <div className="about_img">
                                    <img src="assets/images/farm2.jpg" alt="about" />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="about_item_tb">
                                    <div className="about_item_tbcell">
                                        <div className="base-header base_header_left">
                                            
                                            <h3>What we do</h3>
                                        </div>
                                        <div className="">
                                       <p> Development of an integrated ecosystem including the integration of markets with a 360-degree
approach along the journey of an agriculture commodity from farm to fork with an in-built
mechanism for streamlining and leveraging ‘production to consumption’ lifecycle of all Agri
commodities through the application of modern farm practices and management techniques is the
long-term solution to the problems ailing the Indian agriculture sector, and thereby, economic
integration of the most marginalized section of the society.</p><p><HashLink to="/about#whatwedo" style={{'color': `green`}}>Read more </HashLink></p> 
                                            
                                        </div>

                                    </div>
                                </div>
                            </div>

                            {/*about_wrp*/}
                        </div>
                        {/* row */}
                    </div>

                    
                    {/* container */}
                </section>


                <section className="about-section" >
                    <div className="container" style={{ textAlign: "justify" }}>
                        <div className="row">
                            
                            <div className="col-md-12 col-sm-12">
                                <div className="about_item_tb">
                                    <div className="about_item_tbcell">
                                        <div className="base-header ">
                                            
                                            <h3 style={{ textAlign: "center" }}>The Challenge to solve ‘The Rural Riddle’
</h3>
                                        </div>
                                        <div className="">
                                       <p> Rural distress has been the buzzword around which many policy narratives have been created and debated to make way for interventions in the rural sector with mixed results. While we have been able to make considerable progress on some of the economic parameters after independence, we still have to deal with news of farmers’ suicides and starvation deaths that reflect the enormity of the disconnect that exists just under the veneer of the information revolution and economic growth. Economic integration of a large swathe of population has always been a challenge for a system that fights with its functional deficiencies at the ground level.Agriculture, despite being the mainstay of the Indian rural sector, has not been able to sustain the growth trajectory attained during the period of ‘Green Revolution’ in India. While this sector faces a multitude of problems, the absence of an integrated model to address the issues of sustainability, scale, market connect, storage & processing, optimum price realization, availability of quality inputs, in combination with lack of quality healthcare and education infrastructure, has been something which stands out when we try to analyse the distress like situations in the rural sector....<HashLink to="/about#ruralriddle" style={{'color': `green`}}>Read more </HashLink></p> 
                                            
                                        </div>

                                    </div>
                                </div>
                            </div>

                            {/*about_wrp*/}
                        </div>
                        {/* row */}
                    </div>
                    {/* container */}
                </section>

{/* ================================================================================================================================= */}
 

 <CardsHome/> 


                {/* ================================================================================================================================= */}
                {/*
                <section className="about-section-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                                <div className="about_img">
                                    <img src="assets/images/big_img.png" alt="about" />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="about_item_tb">
                                    <div className="about_item_tbcell">
                                        <div className="base-header base_header_left">
                                            <br></br>
                                            <small>Gramiigo</small>
                                            <h3>The Riddle</h3>
                                        </div>
                                        <div className="">
                                        <ReactReadMoreReadLess
                                                charLimit={400}
                                                readMoreText={"Read more ▼"}
                                                readLessText={"Read less ▲"}
                                            >
                                            {`The rural sector is still largely unexplored and therefore offers numerous opportunities to entrepreneurs and enterprises to tap. The renewed focus of the central government on the rural sector in general and the agriculture sector in particular, has invited the attention of entrepreneurs and the corporate world to invest in the rural sector. But these investments have not translated into any significant improvement to the lives of the majority of the rural households—the bottom of pyramid which fails to participate in or derive benefits from the technological interventions offered by the startups, or from the scale of operations of corporate players.
Another interesting phenomenon has been the course-correction under business pressures by numerous "Agri" startups, which begin with the stated cause of uplifting the marginal farmers, but soon are forced to change to either a B2B model or limit themselves to the more profitable areas of the rural economy, which again, are beyond the reach of average rural households. As a result, most of the benefits still evade the real movers of the rural economy - the marginal farmers and their households - who incidentally form the largest population base, which drives a significant part of the aggregate consumption in the GDP of our country. The problem with all these efforts is not the lack of the right intention or approach but there is somehow a deep invisible disconnect and lack of understanding of the peculiarities of the rural economy and its complications; the inability to look beyond the apparent makes things more complex and the challenge more formidable. Therefore, it’s no surprise that most of the rural sector remains unorganized and opaque.

                                            `}
                                            </ReactReadMoreReadLess>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="about_item">

                                
                            </div>
                            
                        </div>
                        
                    </div>
                   
                </section>
*}
                {/* ================================================================================================================================= */}
                {/* 
     */}

                {/* ================================================================================================================================= */}



                {/* ================================================================================================================================= */}



                {/*<AboutContent /> */}
                <ServiceContent />
               {/* <Funfact /> */}
                <WorkProcess />
                {/* <ChooseUs />  */}

                {/* <PricingList /> */}
                
                <div className="gal">
                <GalleryContent />
                </div>
                {/* <Team /> 
      <Testimonial /> */}
                {/* <BlogList /> */}
                {/* <ClinetCarousel /> */}
                <ContactForm />

            </LayoutOne>
        </Fragment>
    );
};

export default Home;
