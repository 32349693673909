import React, { Component, Fragment } from 'react';
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";
import Breadcrumb from "../components/Breadcrumb";
import emailjs from '@emailjs/browser'; 

import { Container } from '../components/ResearchContainer'

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';


class ResearchPaper1 extends Component {

    

  render() {

    const triggerText = 'Read More';
  const onSubmit = (event) => {
    event.preventDefault(event);
    console.log(event.target.name.value);
    console.log(event.target.email.value);
    var namev = event.target.name.value;
    var emailv = event.target.email.value;
    var templateParams = {
      //name: namev,
      //email: emailv,
      //subject: subjectv,
      message: "Research Paper 1 - name: " + namev + " email: " + emailv  };
      emailjs.send('service_ey2109b', 'template_po50f6k', templateParams,'9hrUjU18skAfBg82l')
  };
    return (
      <Fragment>
        <MetaTags>
          <title>Gramiigo  | Research Paper</title>
          <meta
            name="description"
            content="Organic Food React JS Template."
          />
        </MetaTags>

        <LayoutOne>

          <div className="contact-page">

            {/*====================  breadcrumb area ====================*/}
            <Breadcrumb title="Research Papers" />
            {/*====================  End of breadcrumb area  ================*/}
            {/*====================  Contact Form  area  ====================*/}
            <section className="contact-section">
              <div className="container">
                <div className="base-header">
                  <small>Gramiigo</small>
                    <h3>Economics of Long Queues<br/>
                        <h6>An Analysis of Relationship Between Potato Farmers and Cold-Store Industry</h6>
                    </h3>
                </div>

                <div className="about_item">
                  <p>
                      
                 <h4><u><b>Introduction</b></u></h4>

                As serpentine queues of tractors with trolleys loaded with bags of potatoes interspersed with
                other modes of goods-transport get longer at the gates of omnipresent cold stores in the
                potato belt of Agra in Uttar Pradesh, anxiety among farmers grows deeper as this is the tell-tale
                sign of how remunerative their potato crop is going to be—longer the queues lesser the prices
                their produce is going to fetch that year.<br/><br/>

                For an outsider visiting this area in harvesting season, which lasts from Feb to March, the sight
                of harried farmers desperate to have their produce cold-stored looks like a great business
                opportunity; this unmet demand for cold stores can be leveraged to earn a premium for a
                service which is in short supply at a particular point of time as happens during peak demand
                seasons for many products/services—a simple case of dynamics of demand and supply. But,
                when you go deeper, you realise that there are so many concepts of economics at play to make
                these long queues not so straight to deal with.<br/>

                What prevents a firm/unit/business to scale up prices of its product/services to maximise
                profits when demand goes up – let us try to understand through concepts of economics.

                <h4><u><b>Cold-Store Industry; an overview</b></u></h4>
                Cold store industry is a rental industry which rents out space to potato growers for storing their
                produce at low temperature for a particular period, charges are fixed per bag (50kg) for an
                entire season. Rates are mostly decided collectively (at industry level) before the start of
                harvesting season (Feb – March) every year, usually by associations formed at state and district
                levels. Though individual units are not bound by law to charge rates mandated by associations,
                market factors make it economically prudent to adopt rates decided collectively.<br/><br/>

                Cold-store industry shows elements of ‘perfectly competitive’, ‘monopoly’ as well as
                ‘monopolistic competitive’ markets. If we examine it as a single entity working under an
                association of cold stores deciding rates for its services -- as is the case, for example, of OPEC,
                though not as powerful as earlier, which works as a cartel deciding supplies to influence global
                oil prices as a group of oil-producing nations—it shows characteristics of a monopolistic market
                for the following reasons:<Container triggerText={triggerText} onSubmit={onSubmit} />
                        
                  </p>
                </div> 
              </div>
            </section>
          </div>
        </LayoutOne>
      </Fragment>

    );
  }
}

export default ResearchPaper1;