import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import GalleryContentSupport from './GalleryContentSupport';

class GalleryContent extends Component {

    render() {
        return (<GalleryContentSupport />);
    }

}


export default GalleryContent;


