import React from "react"; 

const UpcomingProj = () => {
  return (
 
	<section className="">
        <div className="container"> 
            <div className="row">
                <div className="col-md-6 col-sm-12">
                    <div className="about_img"> 
                        <img src="assets/images/upcomming.jpg" alt="about" /> 
                    </div>
                </div>
                <div className="col-md-6 col-sm-12">
                    <div className="about_item_tb">
                        <div className="about_item_tbcell">
                             <div className="base-header base_header_left">
                                <small>Gramiigo</small>
                                <h3>Upcoming projects</h3> 
                             </div>
                            <div className="">
                               {/* <h4>Natural Products</h4> */}
                                 <p>India is home to 15 agro-climatic zones with 73 sub-zones, making it one of the most varied landscape with all sorts of geo-climatic conditions to grow a wide variety of agricultural commodities including fruits, vegetables, spices, oilseeds and pulses; India is not just the secondlargest producer of wheat, rice, cotton, silk, tomato, potato, onion, sugarcane, garlic, cashew nut, peanut, tea, and papaya but also occupies the top spot in the production of milk, pulses, millet, jute, ginger, chillies and peppers, banana, mango, guava, and lemons and limes. On the other hand, India offers a unique proposition as a huge market for agriculture commodities with a population of over 1.3 billion which is likely to reach the figure of 1.7 billion in the 2050s before stabilizing and hitting a downward slope. </p>
                            </div>
                            
                        </div>
                        
                    </div>
                    
                </div>
                <div className="col-md-12 col-sm-12">
                <div className="about_item">
                                
                                 <p> One outstanding feature of this huge Indian population is—a significant portion of it, about two-third (68.8%, as per census 2011), live in rural areas, Page 13 of 18 and if we add the part of the population living in semi-urban areas and small towns—which are closely connected to rural areas and its economy—this figure which represents over three-fourth of the total population, even at the lowest consumption level among all income groups, holds significance for its share in total consumption of agricultural commodities. If we consider the consumption of processed food and agriculture by-products, and products manufactured by small-scale rural industry, we can imagine the sheer size of this market available for agriculture produce and rural industry within the rural ecosystem to offer prospects of deriving benefits of humongous scale if connected directly with each-other as producers and consumers. In order to develop an integrated ecosystem for ‘Pre-farm to Post-fork’ value chain, we are working towards connecting the rural markets through GRAMiigo centres spread across the country to act as bases to facilitate smooth movement towards achieving scalability of the operations and establishing supply lines for urban markets through cold-chain network, agri logistics, retail stores and digital marketplace. After our pilot project in Potato belt of Agra, we plan to expand to other Agri-economic zones of India to derive benefits of connected markets and integrated agriculture ecosystem for the upliftment of the rural community.</p> 
                            </div>  
                            </div>
                {/*about_wrp*/}
            </div>
            {/* row */}
        </div>
        {/* container */}
    </section>


  );
};

export default UpcomingProj;
