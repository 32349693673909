import React,{Component} from 'react';
import {Link} from 'react-router-dom';

class ServiceContent extends Component{

    render(){

    const serviceListArray = [
        {
            image:'1.jpg',
            title:'GRAMiigo Seeds',
            description:'As the role of high-yield hybrid varieties of seeds got prominence in the Indian agriculture space,the sale of fake seeds ', 
            link:'initiatives-Grammiigo-seeds',
        },
         {
            image:'2.jpg',
            title:'GRAMiigo Organics',
            description:'Harmful long-term effects of the use of chemical fertilizers and pesticides in agriculture on human health ', 
            link:'initiatives-Grammiigo-organics',
        },
        {
            image:'3.jpg',
            title:'GRAMiigo Farms',
            description:'Gramiigo has embarked on a mission to bring about a sea change in the ground realities in the rural sector. ', 
            link:'initiatives-Grammiigo-farms',
        },
        {
            image:'4.jpg',
            title:'GRAMiigo Traimp (Tractor and Implements)',
            description:'As per the agriculture census 2015-16, the average size of landholding in India is 1.08 hectares while in states such as as Uttar Pradesh, West Bengal', 
            link:'initiatives-Grammiigo-traimp',
        },
        {
            image:'5.png',
            title:'GRAMiigo Rutor (Rural Tourism)',
            description:'A village is not just about its idyllic setting with an abundance of natural beauty—a place inhabited by some simple and affable people; it is also ', 
            link:'initiatives-Grammiigo-rutor',
        },
        {
            image:'6.jpg',
            title:'GRAMiigo Care (Help and Support)',
            description:'‘Gramiigo Care’ is a comprehensive socioeconomic initiative by Gramiigo Foundation which seeks to create opportunities  ', 
            link:'initiatives-Grammiigo-care',
        },
    ]
 

    const serviceListtMap = serviceListArray.map((valu, i) => {
        return(

            <div className="col-md-4 col-sm-12" key={i}> 
                <div className="service-item">
                    <div className="img_serv"> 
                        <Link to={`${valu.link}`}><img src={`assets/images/initiative/${valu.image}`}alt="service" /></Link>
                    </div>
                    <div className="service_text">
                        <Link to={`${valu.link}`}> <h4>{valu.title}</h4></Link>
                        <p>{valu.description} <small> <Link to={`${valu.link}`} ><p style={{'color': `green`}}>Read more </p></Link> </small></p>
                    </div>  
                    <Link to={`${valu.link}`} className="serv_link"><i className="icon-glyph-40"></i></Link>
                </div>
                <br/>
            </div>                

        )
    });

    return(
        <section className="service-section">
            
            <div className="container"> 
                {/* Heading */}
                 <div className="base-header">
                    
                    <h3>Our Initiatives</h3>
                 </div>  
                {/* End: Heading */}

                <div className="row">
                    {serviceListtMap}
                </div> 
                
            </div> 
        </section>
        )

    }
}

 
export default ServiceContent;