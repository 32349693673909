import React, { Component, Fragment } from 'react';
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";
import Breadcrumb from "../components/Breadcrumb";
import emailjs from '@emailjs/browser';

import { Container } from '../components/ResearchContainer'

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';


class ResearchPaper1 extends Component {

    

  render() {

    const triggerText = 'Read More';
  const onSubmit = (event) => {
    event.preventDefault(event);
    console.log(event.target.name.value);
    console.log(event.target.email.value);
    var namev = event.target.name.value;
    var emailv = event.target.email.value;
    var templateParams = {
      //name: namev,
      //email: emailv,
      //subject: subjectv,
      message: "Research Paper 3 - name: " + namev + " email: " + emailv  };
      emailjs.send('service_ey2109b', 'template_po50f6k', templateParams,'9hrUjU18skAfBg82l')
  };
    return (
      <Fragment>
        <MetaTags>
          <title>Gramiigo  | Research Paper</title>
          <meta
            name="description"
            content="Organic Food React JS Template."
          />
        </MetaTags>

        <LayoutOne>

          <div className="contact-page">

            {/*====================  breadcrumb area ====================*/}
            <Breadcrumb title="Research Papers" />
            {/*====================  End of breadcrumb area  ================*/}
            {/*====================  Contact Form  area  ====================*/}
            <section className="contact-section">
              <div className="container">
                <div className="base-header">
                  <small>Gramiigo</small>
                    <h3>How to Achieve 'Sustainable Growth' for Rural India</h3>
                </div>

                <div className="about_item">
                  <p>
                      
                 <h4><u><b>Objective</b></u></h4>
                        
                        The objective of this project is to study the rural economy, its challenges in terms of sluggish
                        growth in the light of the reports of farmers’ suicide and rural distress.
                        To study the untapped potential of rural market and reasons of agrarian distress that point
                        towards non-inclusive growth of Indian economy; Try to find out ways and means to increase
                        income in the rural sector to create demand; critical review of current Govt. policies

                        <h4><u><b>Methodology</b></u></h4>
                        
                        Study research papers and reports on the rural economy; try to connect data with
                        macroeconomic concepts to understand the causes and effects of Govt. policies, interventions
                        in the agriculture sector and the rural economy. Co-relate the findings with the empirical
                        evidence on the ground in rural India.

                        <h4><u><b>Research Questions</b></u></h4>
                        <ul>
                          <li>• What are the reasons for rural distress?</li>
                          <li> • How much schemes like loan waiver, direct transfer of benefits have helped the rural
                        economy?</li>
                        <li> • What policy measures are needed for inclusive growth in the Indian economy?</li>
                        <li>• What can be the long term sustainable solution for rural growth?</li>
                        <li>• Do we need to look beyond the agriculture sector to boost the rural economy?</li>
                        </ul>
                        
                        <h4><u><b>Key Findings</b></u></h4>
                        
                        Non-inclusive growth, faulty implementation and limited reach of various welfare schemes, lack
                        of proper monitoring system at ground level, absence of functioning social infrastructure, low
                        penetration of institutionalized credit, peculiarities of rural set-up are the reasons for less than
                        optimal growth in the rural sector. While rural India presents immense growth potential,
                        challenges in the agriculture sector drag it down; from structural and market deficiencies to
                        issues of the water crisis, the Indian agriculture sector is faced with challenges that put a
                        question mark on the sustainability of the current farm practices. For a long term sustainable
                        growth in the rural sector, we need a holistic approach to harness the real economic potential
                        of rural India. 
                <Container triggerText={triggerText} onSubmit={onSubmit} />
                
                  </p>
                </div> 
              </div>
            </section>
          </div>
        </LayoutOne>
      </Fragment>

    );
  }
}

export default ResearchPaper1;