import React from "react";


function Modal1({ setOpenModal }) {
  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="titleCloseBtn">
          <button
            onClick={() => {
              setOpenModal(false);
            }}
          >
            X
          </button>
        </div>
        
        <div className="">
        <div className="product_img" style={{textAlign: "center"}}>  
                        <img src="assets/images/cards/2.png"  alt="product" />
                        
                    </div>
          <p> The most marginalized section of society is at the core of our planning; therefore, our focus is always on marginal and landless farmers. In addition to agri & allied services at below cost price, we extend our support in all areas which the rural sector finds itself struggling with. From healthcare to education, a wide spectrum of GRAMiigo support systems is poised to create a platform for holistic development.</p>
        </div>
        
      </div>
    </div>
  );
}

export default Modal1;
