import React,{Component, Fragment} from 'react'; 
import ServiceSidebar from '../../components/ServiceSidebar'; 
import MetaTags from "react-meta-tags";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../components/Breadcrumb";


class InitiativesGrammiigoTraimp extends Component{
	render(){

	const serviceTopArray = [
		{
			image:'4.jpg',
            title:"GRAMiigo Traimp (Tractor and Implements)",
			desPartOne:"As per the agriculture census 2015-16, the average size of landholding in India is 1.08 hectares while in states such as Uttar Pradesh, West Bengal, Andhra Pradesh, Tamilnadu, Bihar it is less than one hectare. Almost 50% of the total operational landholdings are of the size less than 0.5 hectare while 86% of the total landholdings are of the size less than 2 hectares. Small landholdings make it economically imprudent and unviable to invest in the mechanization of agriculture activities for small and marginal farmers which, in turn, leads to higher input costs as they have to hire tractors and implements for tilling and other activities.",
            desPartTwo:"Traimp is an initiative by Gramiigo to infuse efficiencies of scale into farming activities of small and marginal farmers by mechanization through tractors and implements which are made available to them as a part of a comprehensive support system being developed as a pilot project", 
		},
	]
 
    const serviceBottomArray = [
        {
            icon:'check.png',
            title:"100% Satisfaction",
            des:"Experienced staff read nal Experienced sto help you full Prond Experienced eaelp you help anytime you",  
        },
        {
            icon:'check.png',
            title:"Experienced Staff",
            des:"Experienced staff read nal Experienced sto help you full Prond Experienced eaelp you help anytime you",  
        },
    ]

    const serviceToptMap = serviceTopArray.map((val, i) => {
        return(
		    <div className="single_service_left" key={i}> 
                <img src={`assets/images/initiative/${val.image}`} alt="" />
                <h4>{val.title}</h4>
                <p>{val.desPartOne}</p>
                <p>{val.desPartTwo}</p>
            </div>
        )
    });

    const serviceBottomtMap = serviceBottomArray.map((val, i) => {
        return(
            <div className="col-md-6 col-sm-12" key={i}>
                <div className="sing_service_item">
                    <div className="icon-serv"> 
                        <img src={`assets/images/${val.icon}`} alt="" />
                    </div>
                    <h4>{val.title}</h4>
                    <p>{val.des}</p>
                </div>
            </div>
        )
    });


	return(
        <Fragment>
        <MetaTags>
          <title>Gramiigo | Traimp</title>
          <meta
            name="description"
            content="Gramiigo"
          />
        </MetaTags>
        <LayoutOne>

       <div className="single-services-page">

            {/*====================  breadcrumb area ====================*/}

            <Breadcrumb title="GRAMiigo Traimp" />
            
            {/*====================  End of breadcrumb area  ====================*/} 


            {/*====================  Single Services area ====================*/}
            <div className="single_service">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-sm-12">

                            {serviceToptMap}

                            
                        </div>

                        <div className="col-md-4 col-sm-12">

                            <ServiceSidebar />
                            
                        </div>

                    </div>
                </div>
            </div>
             {/*====================  End Single Services area ====================*/} 
        </div>
        
          </LayoutOne>
        </Fragment>

 	)

	}
}

 
export default InitiativesGrammiigoTraimp;