import React,{Component, Fragment} from 'react';
import {Link} from 'react-router-dom'; 
import BlogSidebar from '../components/BlogSidebar'; 
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";
import Breadcrumb from "../components/Breadcrumb";
import { HashLink } from 'react-router-hash-link';

class SingleBlog2 extends Component{

 
    render(){

    const postTag = [
        { 
            name:'Food',  
            link: 'single-blog',
        },
        { 
            name:'Red',  
            link: 'single-blog',
        },
        { 
            name:'Grapes',  
            link: 'single-blog',
        },
        { 
            name:'Fresh',  
            link: 'single-blog',
        },
    ]
   
 
    const postTagMap = postTag.map((val, i) => {
        return(
            <Link to={`${val.link}`} key={i}> {val.name}</Link>
        )
    });


    return(
        <Fragment>
        <MetaTags>
          <title>Gramiigo | Blog</title>
          <meta
            name="description"
            content="Gramiigo"
          />
        </MetaTags>
        <LayoutOne>

	    <div className="single-blog">

            {/*====================  breadcrumb area ====================*/}

            <Breadcrumb title="Blog" />
            
            {/*====================  End of breadcrumb area  ====================*/} 

	        <div className="blog_container single_blog_container">
	            <div className="container"> 
	                <div className="row">     

                        <div className="col-lg-12 col-xs-12 blog-area">
                            <div className="blog-post-list">

                                <div id='blogstart' className="blog_wrp">
                                    <Link to="blog2" >
                                        <img src="assets/images/blog/blog2.jpg" alt="" />
                                    </Link>
                                    <div className="blog_info">
                                        
                                        
                                            <h4>When Solution Is Not One-Stop</h4> 
                                        
                                        <p>Post-harvest management and value addition for a crop is an area where even the most progressive farmers falter to realize its full potential for various reasons—lack of technological know-how, lack of exposure to market conditions and its needs, lack of awareness on the prospects and opportunities, and lack of entrepreneurial culture and professional support in the agriculture sector are some of them.
                                            Reports in media keep appearing, off and on, about farmers dumping their produce to rot in the open when farmers fail to recover even the transportation costs to take their produce to the market, which, many argue, is the result of inadequate (cold) storage capacity creation in the agriculture sector that adds to a price-crash situation in case of a bumper crop. The argument holds as far as the role of storage capacity in giving a strong handle to farmers to leverage it to escape a situation of distress sale at the time of peak harvest season is concerned. However, is the problem as simple as that to be taken care of just by adding storage capacity? 
                                            A study by National Centre for Cold Chain Development (NCCD) in 2015 found that India has the cold-storage capacity of 32 million tonnes as against the requirement of around 36 million tonnes which increased further to 36.77 million tonnes in the year 2019 as per a statement made in parliament by Union Agriculture Minister. Let’s have a look at some figures. As per a study done by the Central Institute of Post-Harvest Engineering and Technology (CIPHET) in 2015 :-

                                            </p> 

                                        
                                           
                                            <ol>
                                                <li>
                                                The economic value of quantitative loss of 45 crops/commodities was found to be about Rs 92651 crores at average annual wholesale prices of 2014 using production data of 2012-13
                                                </li>
                                                <li>
                                                For fruits, the losses ranged from 6.70% (Papaya) to 15.88% (Guava)
                                                </li>
                                                <li>
                                                The losses in vegetables varied from 4.58% (Tapioca) to 12.44% (Tomato)
                                                </li>
                                                <li>
                                                Cumulative average range of losses altogether for food grains, oilseeds and fruits and vegetables were found to be 4.65% to 15.88%

                                                </li>
                                                <li>
                                                Highest contribution (34%) towards economic loss was from horticulture produce sector (fruits and vegetables) followed by cereals (22.3%) and livestock produce (20%). The reasons for high economic loss in fruits and vegetables are (i) high market prices of fruits and vegetables, (ii) soft texture, high water content and perishable nature make it difficult to handle and store.
                                                </li>
                                                
                                                
                                                </ol>

                                                <p>
                                                If we look at comparative figures for losses at different stages from production to consumption for three major vegetable crops, namely potato, tomato and onion, we can observe that the overall losses in the crop of tomato are the highest, while for potato and onion are comparable. This difference can be attributed to higher perishability of tomato compared to potato and onion. 

                                                </p>

<div style={{textAlign : "center"}}>
<img src='assets/images/blog/blog2.jpg'  style={{ width: "500px"}}  />
</div>

<p><i>Source: Jha SN, Vishwakarma RK, Ahmad T, Rai A and Dixit AK (2015). Report on assessment of quantitative harvest and post-harvest losses of major crops and commodities in India. ICAR-All India Coordinated Research Project on Post-Harvest Technology, ICAR-CIPHET, Ludhiana </i></p>

<p>However, if we compare the losses at storage channels for potato and onion, there are marked differences—losses are about three times higher for onion. If seen in the light of the fact that potato enjoys the privilege of having about two-third of total cold storage capacity in India at its disposal, the difference is attributable and explained. Does it explain everything? Has the availability of cold storage facilities for potato helped potato farmers in getting remunerative prices and avoid situations of distress sale? 
If we go by the reports of price crashes in the market, the situation looks no different from other vegetable crops. For a better understanding, let us compare two reports in downtoearth.org—a print and digital magazine, on situations of price-crash for tomato and potato in the same winter season towards the end of the year 2018. As per the reports: -
</p>
                                       <p>Tomato</p>
                                       <ol>
                                        <li>
                                        Wholesale prices of tomatoes have crashed by nearly 54 per cent (on an average) between November 2017 and 2018. The average price of tomatoes in the past five years (2014-15 to 2017-18) has come down by nearly 15 per cent.
                                        </li>
                                        <li>
                                        In case of bumper production, farmers flood the markets with their produce. But the story would have been different if the post-harvest infrastructure was well developed to provide farmers the facility to hold their produce and sell in moderate quantities rather than overcrowding the market with their produce. Farmers do not have the luxury of time to be kept at convenience and are forced to resort to a push mode into market. It is further hastened because of reduced saleable timelines, and this frequently leads to distress selling or roadside discards.
                                        </li>
                                        <li>
                                        As on March 31, 2017, there are 7,645 cold storages with a capacity of 34.95 million M.T. in the country. Of this, 75 per cent of storage facilities are meant for potatoes. Only 25 per cent is available for other produces (including tomatoes)
                                        </li>
                                       </ol>

                                       <p>Potato </p>
                                       <ol>
                                        <li>
                                        ‘Hit by low wholesale prices, potato farmers in Uttar Pradesh and Punjab have not been able to recover even the production cost of the crop as market price of potatoes crashed by 39-50 per cent in December 2018. The average price of potatoes also crashed by nearly 33 per cent in the past five years (between 2012-13 and 2017-18)

                                        </li>
                                        <li>
                                        ‘The last few years have witnessed a surplus production of potatoes in Punjab that have not been promptly transported to other states. As a result, potato growers have been forced to sell their crop at throwaway rates.’
                                        </li>
                                        <li>
                                        ‘While farmers struggle to sell their new produce, they also have large amount of stocks lying in cold storages. The low prices have made it unfeasible for them to pay for their potatoes in these storages and they prefer to leave them over there’

                                        </li>
                                        
                                       </ol>

                                       <p>
                                       While the report on price-crash for tomato cites the lack of post-harvest storage infrastructure as the reason behind the problem, the report on potato—which has no such problem for storage—tells us that the farmers are unable to sell their produce as new crop has come in the market and old stocks are still lying in cold stores—a clear sign of demand-supply mismanagement on the part of all stakeholders. Obviously, just by creating storage capacity we cannot solve a problem which requires a multidimensional approach to focus on all pain-points in supply-chain network considering limitations of individual farmers.  
                                       </p>

                                       <p>
                                       (Cold) Storage capacity (and cold chain) creation can infuse efficiencies in distribution channel by giving an extended window to farmers for staggered supply to the market to avoid a situation of glut for better price realization. Reduction in losses due to spoilage is another benefit of cold storage facilities. Still, we have to recognize the fact that the losses in storage channels (which include farm, warehouse/cold-store, wholesaler, retailer, and processing unit) form about 25% of the total losses in fruits and vegetables during harvest and post-harvest activities in which losses at the level of wholesaler and retailer form about 90% of the losses at the level of storage channels. Another activity that accounts for a significant part in losses is the transportation, which roughly makes up about 10% of total losses —apparently, instead of focusing just on the development of cold store facilities, we need to focus on the development of a comprehensive end-to-end cold chain network for perishables for efficient handling and management. 

                                       </p>

                                       <p>Policymakers in India have always been enamoured of one-stop solution for every problem—an approach which discounts the ecosystem in which a problem takes root; cold-storage capacity generation for horticulture produce has been promoted as one of such solution for post-harvest wastage and to ensure remunerative prices for perishable farm produce, which has its own limitation due to some inherent structural challenges which exist in the agriculture sector; farmers, cold-store industry, food processing industry, and logistics management for transportation, all work in silos with minimum linkages in terms of sharing of information with no space for a planned, coordinated approach to create shared value in an ecosystem that discourages innovation, entrepreneurial culture, and scale benefits. 
</p>
                                     {/*   <div className="post_tags">
                                            <h5> Tags</h5>
                                            {postTagMap} 
                                        </div> 
                                        <div className="post_share footer_socil"> 
                                            <ul className="list-icons link-list footer_soc">
                                                <li>
                                                    <Link to={process.env.PUBLIC_URL + "/single-blog"}><i className="fa fa-facebook"></i></Link>
                                                </li>
                                                <li>
                                                    <Link to={process.env.PUBLIC_URL + "/single-blog"}><i className="fa fa-twitter"></i></Link>
                                                </li>
                                                <li>
                                                    <Link to={process.env.PUBLIC_URL + "/single-blog"}><i className="fa fa-behance"></i></Link>
                                                </li>
                                                <li>
                                                    <Link to={process.env.PUBLIC_URL + "/single-blog"}><i className="fa fa-instagram"></i></Link>
                                                </li>
                                                <li>
                                                    <Link to={process.env.PUBLIC_URL + "/single-blog"}><i className="fa fa-pinterest"></i></Link>
                                                </li>
                                            </ul>
                                        </div>  */}

                                    </div>
                                </div>
                            </div> 
                            

                            <div className="post-option">
                                {/*<div className="pull-left">
                                    <Link to={process.env.PUBLIC_URL + "/single-blog"} className="prev-post"><span className="arrow-icon icon-glyph-205"></span> &nbsp;</Link>
                                    </div>*/}
                                <span className="middle-icon">
                                    <Link to={process.env.PUBLIC_URL + "/blog-two"} className="icon-glyph-99"> </Link>
                                </span>
                                <div className="pull-right">
                                    <HashLink to={process.env.PUBLIC_URL + "/single-blog#blogstart"} className="next-post"> &nbsp;<span className="arrow-icon icon-glyph-204"></span></HashLink>
                                </div>
                            </div>
 
                            {/*  comments list */} 
                             
                            
                            {/*  comments list */} 

 
                            {/*  comments box */} 
                            
                            {/*  End: comment box */}  
                        </div>
                        {/* Blog Area */}


                        {/*  Widget Area */} 
                        
                        
 
                        {/* End: Widget Area */} 

	                </div> 
	            </div> 
	        </div>


		</div> 

        </LayoutOne>
        </Fragment>
        
        )

    }
}

 
export default SingleBlog2;