 
import React from 'react';
import {Link} from 'react-router-dom';

const Header = () => {
	return (
 
    <div className="header_topbar">
        {/* Logo */}
        <div className="container"> 
                <div className="header_top_right list-unstyled">
                    <ul>
                        <li><i className="fa fa-phone"></i>+91 ***** *****</li>
                        <li><i className="fa fa-globe"></i>B-17, Geetanjali Enclave, Malviya Nagar, New Delhi-110017</li>
                    </ul>
                </div>
                <div className="header_top_left">
                    <ul className="header_socil list-inline pull-left">  
                        <li><i className="fa fa-envelope"></i>contact@gramiigo.org</li>
                      {/*
                        <li>
                            <a href="//facebook.com" target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook"></i></a>
                        </li>
                        <li>
                            <a href="//twitter.com" target="_blank" rel="noopener noreferrer"><i className="fa fa-twitter"></i></a>
                        </li>
                        <li>
                            <a href="//instagram.com" target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram"></i></a>
                        </li>
                        <li>
                            <a href="//pinterest.com" target="_blank" rel="noopener noreferrer"><i className="fa fa-pinterest"></i></a>
                        </li>
    */}
                    </ul>
                </div>
                {/*<a href='http://localhost:3000/login' className="more-link" >Join Gramiigo</a>*/}
            </div>
 
    </div> 
 
	)
}


export default Header;
