import React from "react";


function Modal2({ setOpenModal }) {
  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="titleCloseBtn">
          <button
            onClick={() => {
              setOpenModal(false);
            }}
          >
            X
          </button>
        </div>
        
        <div className="">
        <div className="product_img" style={{textAlign: "center"}}>  
                        <img src="assets/images/cards/3.png"  alt="product" />
                        
                    </div>
          <p> Rural sector is not all about impoverishment. From big farmers to spirited entrepreneurs all operate in an ecosystem that forms the backbone of a huge informal economy. GRAMiigo gives platform to all such players to scale up and avail benefits of a professionally run entity by collaborating with GRAMiigo</p>
        </div>
        
      </div>
    </div>
  );
}

export default Modal2;
