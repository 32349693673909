import React from "react"; 

const CareersContent = () => {
  return (
 
	<section className="">
        <div className="container"> 
            <div className="row">
            {/*    <div className="col-md-6 col-sm-12">
                    <div className="about_img"> 
                        <img src="assets/images/about.jpg" alt="about" /> 
                    </div>
            </div> */}
                <div className="col-md-12 col-sm-12">
                    <div className="about_item_tb">
                        <div className="about_item_tbcell">
                             <div className="base-header base_header_left">
                                <small>Gramiigo</small>
                                <h3>Careers</h3>
                             </div>
                            <div className="about_item">
                              {/*  <h4>Natural Products</h4> */}
                                 <p>GRAMiigo offers opportunities to work with a highly professional team who share backgrounds
of IITs and IIMs with vast experience in their respective fields. If you share the zeal to excel in
your domain of expertise with a self-driven approach to set goals and achieve them, if you are
not afraid of taking the path less trodden and derive satisfaction from the fact that your work
plays a part in bringing about some positive changes in the society, then opportunities exist in the
following areas to work with us: -
                                    <ol>
                                        <li> Seed Development & Tissue culture</li>
                                        <li> Precision Farming & AI based solutions</li>
                                        <li> Crop Advisory & Soil Nutrition</li>
                                        <li> Hydroponics & Aeroponics Technology</li>
                                        <li> Community Kitchens, Food and Nutrition Management</li>
                                        <li> Logistics & cold-chain management</li>
                                        <li> Food processing</li>
                                        <li> Product Development & Marketing</li>
                                        <li> Sales & Retail Chain Management</li>

                                        
                                    </ol>
                                    Send your resume to open discussion for best in the industry offers to contact@gramiigo.org
                                    </p>
                            </div>
                            
                        </div>
                    </div>
                </div>
                {/*about_wrp*/}
            </div>
            {/* row */}
        </div>
        {/* container */}
    </section>


  );
};

export default CareersContent;
