import React , {Component, Fragment } from 'react'; 
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";
import Breadcrumb from "../components/Breadcrumb";


class GalleryTwo extends Component{

	componentDidMount() {
		const modal = document.querySelector(".model");
		const images = document.querySelectorAll(".single-project-item")
		const imgText = document.querySelector(".caption")
		const popUpImage = document.querySelector(".full-Image")		
		const header = document.querySelector(".blog-header");
		const footer = document.querySelector(".footer-section");

		images.forEach(img => {
			img.addEventListener('click', () => {
				const imageData = img.getAttribute("data-original")
				const altText = img.getAttribute("alt")
				imgText.textContent = altText
				popUpImage.src = imageData
				
				modal.classList.add("open")
				popUpImage.classList.add("open")
				header.classList.add("open")
				footer.classList.add("open")
			})
		});
		
		modal.addEventListener('click', (event) => {
			if(event.target.classList.contains("model")) {
				modal.classList.remove("open")
				popUpImage.classList.remove("open")			
				header.classList.remove("open")				
				footer.classList.remove("open")
			}
		})
	}

    render(){

	    const galleryListArray = [
	        {
	            image:'gallery-1.jpg',
	            title:'GRAMiigo Office',
	            category:'Research and development in agriculture'
	        },
 	        {
	            image:'gallery-2.jpg',
	            title:'Lake',
	            category:'Rainwater Harvesting'
	        },
	        {
	            image:'gallery-3.jpg',
	            title:'Hi Tech Poly House',
	            category:'Research and development in agriculture'
	        },
 	        {
	            image:'gallery-4.jpg',
	            title:'Bottlegourd farm',
	            category:'Precision Farming'
	        },
	        {
	            image:'gallery-5.jpg',
	            title:'Dairy Farm',
	            category:'Gramiigo Care'
	        },
 	        {
	            image:'gallery-6.jpg',
	            title:'louging House',
	            category:'GRAMiigo Rutor (Rural Tourism)'
	        },
			{
	            image:'gallery-7.jpg',
	            title:'Our Farm',
	            category:'Precision Farming'
	        },
 	        {
	            image:'gallery-8.jpg',
	            title:'Dairy Farm',
	            category:'Gramiigo Care'
	        },
            {
	            image:'gallery-9.jpg',
	            title:'Our Team',
	            category:'Field work'
	        },
 	        {
	            image:'gallery-10.jpg',
	            title:'Our Farm',
	            category:'Precision Farming'
	        },
			{
	            image:'gallery-11.jpg',
	            title:'Our team',
	            category:'Office'
	        },
            {
	            image:'gallery-12.jpg',
	            title:'Hi Tech Poly House',
	            category:'Research and development in agriculture'
	        },
	    ]

	    const galleryListtMap = galleryListArray.map((valu, i) => {
	        return(
 
                <div className="col-md-4 col-sm-12 web graphics" key={i}>
                    <div className="single-project-item" data-original={`assets/images/${valu.image}`} alt={valu.title} style={{ backgroundImage: `url(assets/images/${valu.image})` }}>
                        <div className="project-hover">
                            <div className="project_cnt"> 
                                <h6>{valu.title}</h6>
                                <span>{valu.category}</span>
                            </div>
                        </div>
                    </div>
                </div>  
  
	        )
	    });

    	return(

		<Fragment>
            <MetaTags>
              <title>Grammigo | Gallery </title>
              <meta
                name="description"
                content="Organic Food React JS Template."
              />
            </MetaTags>
            <LayoutOne>

            <div className="work-page-two">
 

				{/*====================  breadcrumb area ====================*/}

				<Breadcrumb title="Our Gallery" />
			
				{/*====================  End of breadcrumb area  ================*/}

  
				<section className="work-section">
			        <div className="container">
			        	{/* Heading */}
			            <div className="base-header">
			                <small>Our Latest Work</small>
			                <h3> Special Gallery </h3>
			            </div>
			 			{/* End: Heading */}

			            <div className="row projects-list">  

			                {galleryListtMap}

			            </div> 
			        </div> 
			    </section>


                {/*==================== End : Work Section ====================*/}  

			</div>
			<div className="model">
				<img src='' className="full-Image" />
				<p className="caption"></p>
			</div>
            </LayoutOne>
        </Fragment>
        )
    }
}

export default GalleryTwo;


