import React from "react";


function Modal1({ setOpenModal }) {
  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="titleCloseBtn">
          <button
            onClick={() => {
              setOpenModal(false);
            }}
          >
            X
          </button>
        </div>
        
        <div className="">
        <div className="product_img" style={{textAlign: "center"}}>  
                        <img src="assets/images/homecard/1.png"  alt="product" height={'250px'}/>
                        
                    </div>
                    <br></br>
          <p>From growing its own crops for every use to having our own line of production of soil nutrients in the form of vermicompost, vermiwash, jivamrit and herbal pesticides, GRAMiigo Farms ensure strict control on every input to make the produce chemical free in real sense
</p>
        </div>
        
      </div>
    </div>
  );
}

export default Modal1;
