import React , {Component, Fragment } from 'react';
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";
import Slider from "react-slick";  
import Breadcrumb from "../components/Breadcrumb";

class Gallery extends Component{
    
    render(){

        var settings = {
          className: "center",
          autoplay: true,
          autoplaySpeed: 5000,
          dots: false,
          arrows:false,
          infinite: true,
          centerMode: true,
          centerPadding: '200px',
          speed: 500,
          slidesToShow: 3,
          rows: 2, 
          slidesToScroll: 1,
              responsive: [
                {
                  breakpoint: 1199,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '80px',
                    arrows: false,
                    dots: false,
                  }
                },
                {
                  breakpoint: 991,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    centerPadding: '40px',
                    centerMode: false,
                    arrows: false,
                    dots: false
                  }
                },
                {
                  breakpoint: 767,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1, 
                    centerMode: false,
                    arrows: false,
                    dots: false
                  }
                },
                {
                  breakpoint: 580,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                    arrows: false,
                    dots: false
                  }
                },

              ]
        };

        let imageGalleryData = [
            {
                image: 'gallery-1.jpg',
                title: 'GRAMiigo Office',
                category: 'Research and development in agriculture'
            },
            {
                image: 'gallery-2.jpg',
                title: 'Lake',
                category: 'Rainwater Harvesting'
            },
            {
                image: 'gallery-3.jpg',
                title: 'Hi Tech Poly House',
                category: 'Research and development in agriculture'
            },
            {
                image: 'gallery-4.jpg',
                title: 'Bottlegourd farm',
                category: 'Precision Farming'
            },
            {
                image: 'gallery-5.jpg',
                title: 'Dairy Farm',
                category: 'Gramiigo Care'
            },
            {
                image: 'gallery-6.jpg',
                title: 'louging House',
                category: 'GRAMiigo Rutor (Rural Tourism)'
            },
            {
                image: 'gallery-7.jpg',
                title: 'Our Farm',
                category: 'Precision Farming'
            },
            {
                image: 'gallery-8.jpg',
                title: 'Dairy Farm',
                category: 'Gramiigo Care'
            },
            {
                image: 'gallery-9.jpg',
                title: 'Our Team',
                category: 'Field work'
            },
            {
                image: 'gallery-10.jpg',
                title: 'Our Farm',
                category: 'Precision Farming'
            },
            {
                image: 'gallery-11.jpg',
                title: 'Our team',
                category: 'Office'
            },
            {
                image: 'gallery-12.jpg',
                title: 'Hi Tech Poly House',
                category: 'Research and development in agriculture'
            },
        ]; 

        let ImageGalleryDataList = imageGalleryData.map((val, i) => {
            return(

                <div className="col-md-12 swiper-slide service-gallery__single-slide"  key={i}>
                    <div className="project-item item">
                        <div className="project_slide_img item"> 
                            <img src={`assets/images/${val.image}`} alt="product" onClick={this.handleShowDialog} />
                        </div>
                        <div className="project_text"> 
                            <h4>{val.title}</h4>
                            <span className="project_link">{val.category}</span>
                        </div>
                    </div>
                </div>
            )
        });


        return(

        <Fragment>
            <MetaTags>
              <title>FuodBorne | Gallery </title>
              <meta
                name="description"
                content="Organic Food React JS Template."
              />
            </MetaTags>
            <LayoutOne>

           <div className="gallery-page">

                {/*====================  breadcrumb area ====================*/}

                <Breadcrumb title="Our Gallery" />
            
                {/*====================  End of breadcrumb area  ================*/}


                {/*====================  Gallery area ====================*/}
                <section className="project-section">   
                    <div className="container-fluid">  
                        <div className="project_list_one">
                            <div className="container">
                                <div className="row"> 
                                    <div className="col-md-12">
                                        <div className="base-header">
                                            <small>Our Latest Product</small>
                                            <h3> Special Gallery </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="project_slider_one">
                                <Slider {...settings}>

                                    {ImageGalleryDataList} 

                                </Slider>

                            </div> 
                        </div>
                    </div>
                </section>
                {/*==================== End:  Gallery area ====================*/}
            </div>
            </LayoutOne>
        </Fragment>

        );
    }
}

export default Gallery;   