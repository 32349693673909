 
import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import { useState } from "react";
import Modal1 from './orgcard/Modal1'
import Modal2 from './orgcard/Modal2'
import Modal3 from './orgcard/Modal3'

function CardsHome(){

    
    const [modalOpen1, setModalOpen1] = useState(false);
    const [modalOpen2, setModalOpen2] = useState(false);
    const [modalOpen3, setModalOpen3] = useState(false);
    
 

    return( 

		<section className="product-section">
		    <div className="container">
        {/*    <div className="base-header">
		            <small> Our Featured Products</small>  
		            <h3> Organic products</h3> 
    </div>*/}
		        <div className="row">

		            
                <div className="col-md-4 col-sm-12" > 
                <div className="product_wrp" onClick={() => { setModalOpen1(true);}} >
                    <div className="product_img"> 
                        <img src="assets/images/homecard/1.png" alt="product" />
                        
                    </div>
                    <div className='card-body'>
                        <h4>Pure Organics</h4>
                        </div>
                    <div className="project_view">
                        
                    </div>
                </div>
            </div>

            <div className="col-md-4 col-sm-12" > 
                <div className="product_wrp" onClick={() => { setModalOpen2(true);}} >
                    <div className="product_img"> 
                        <img src="assets/images/homecard/2.png" alt="product" />
                        
                    </div>
                    <div className='card-body'>
                        <h4>Passion for perfection</h4>
                        </div>
                    <div className="project_view">
                        
                    </div>
                </div>
            </div>


     

            <div className="col-md-4 col-sm-12" > 
                <div className="product_wrp" onClick={() => { setModalOpen3(true);}} >
                    <div className="product_img"> 
                        <img src=" assets/images/homecard/3.png" alt="product" />
                        
                    </div>
                    <div className='card-body'>
                        <h4>Payback to  society</h4>
                        </div>
                    <div className="project_view">
                        
                    </div>
                </div>
            </div>



             {modalOpen1 && <Modal1 setOpenModal={setModalOpen1} />}
             {modalOpen2 && <Modal2 setOpenModal={setModalOpen2} />}
             {modalOpen3 && <Modal3 setOpenModal={setModalOpen3} />}
		        </div>
		    </div>
		</section>

        )

    }


 
export default CardsHome;