import React,{Component, Fragment} from 'react';
import {Link} from 'react-router-dom'; 
import BlogSidebar from '../components/BlogSidebar'; 
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";
import Breadcrumb from "../components/Breadcrumb";
import { HashLink } from 'react-router-hash-link';

class BlogPagTwo extends Component{

    state = {
        heading: 'Latest news',
        subHeading: 'articles & tips',
    } 

    render(){

    const blogListArray = [
        {
            image:'poly.jpg',
            commentCount:'0 comments',
            date:'6 Nov 2019', 
            title:'When Innovation Fails to Take Ground in The Agriculture Sector', 
            postExcerpt:'It will not be an overstatement if we say that farmers are the most optimistic and big risk-takers when we consider the conditions and uncertainties they negotiate while operating in the agriculture sector. They are into an activity in which success or failure is highly dependent on the most uncertain factor—weather conditions. However, all is not well even if weather conditions work favorably for them in a particular season.', 
            link: 'single-blog',
        },
         {
            image:'blog2.jpg',
            commentCount:'0 comments',
            date:'6 Nov 2019', 
            title:'When Solution Is Not One-Stop', 
            postExcerpt:'Post-harvest management and value addition for a crop is an area where even the most progressive farmers falter to realize its full potential for various reasons—lack of technological know-how, lack of exposure to market conditions and its needs, lack of awareness on the prospects and opportunities, and lack of entrepreneurial culture and professional support in the agriculture sector are some of them.', 
            link: 'blog2',
        },
    ]

  
    const blogListtMap = blogListArray.map((valu, i) => {
        return(
            <div className="col-sm-12" key={i}> 
                <div className="blog_wrp">
                    
                        <img src={`assets/images/blog/${valu.image}`} alt="" />
                    
                    <div className="blog_info">
                        
                        <HashLink to={`${valu.link}`+"#blogstart"}>
                            <h4>{valu.title}</h4> 
                        </HashLink> 
                        <p>{valu.postExcerpt}</p> 
                        <HashLink to={`${valu.link}`+"#blogstart"} className="more-link">Read more </HashLink> 
                    </div>
                </div>
            </div>
        )
    });


    return(
        <Fragment>
        <MetaTags>
          <title>Gramiigo | Blog</title>
          <meta
            name="description"
            content="Organic Food React JS Template."
          />
        </MetaTags>
        <LayoutOne>

	    <div className="blog-page-two">

 
            {/*====================  breadcrumb area ====================*/}

            <Breadcrumb title="Blog" />
        
            {/*====================  End of breadcrumb area  ================*/}


	        <div className="blog_container">
	            <div className="container"> 
	                <div className="row">     

                        <div className="col-md-12 col-sm-12 blog-area">
                            <div className="row">

                                {/* Blog Item */}
                                {blogListtMap} 
         

                                {/* Blog Pagination */}
                                
                            </div>
                        </div>
                        {/* Blog Area */}


                        {/*  Widget Area */} 
                        
                       
 
                        {/* End: Widget Area */} 

	                </div> 
	            </div> 
	        </div>


		</div> 

          </LayoutOne>
        </Fragment>

        )

    }
}

 
export default BlogPagTwo;