import React, { Component, Fragment } from 'react';
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";
import Breadcrumb from "../components/Breadcrumb";
import emailjs from '@emailjs/browser';

import { Container } from '../components/ResearchContainer'


class ResearchPaper2 extends Component {

    

  render() {

    const triggerText = 'Read More';
  const onSubmit = (event) => {
    event.preventDefault(event);
    console.log(event.target.name.value);
    console.log(event.target.email.value);
    var namev = event.target.name.value;
    var emailv = event.target.email.value;
    var templateParams = {
      //name: namev,
      //email: emailv,
      //subject: subjectv,
      message: "Research Paper 2 - name: " + namev + " email: " + emailv  };
      emailjs.send('service_ey2109b', 'template_po50f6k', templateParams,'9hrUjU18skAfBg82l')
  };
    return (
      <Fragment>
        <MetaTags>
          <title>Gramiigo | Research Paper</title>
          <meta
            name="description"
            content="Organic Food React JS Template."
          />
        </MetaTags>

        <LayoutOne>

          <div className="contact-page">

            {/*====================  breadcrumb area ====================*/}
            <Breadcrumb title="Research Papers" />
            {/*====================  End of breadcrumb area  ================*/}
            {/*====================  Contact Form  area  ====================*/}
            <section className="contact-section">
              <div className="container">
                <div className="base-header">
                  <small>Gramiigo</small>
                  <h3>Optimization of Farming Value Chain<br/>
                        <h6>Actionable Areas for Interventions</h6>
                    </h3>

                </div>


                <div className="about_item">
                  <p>
                  <h4><u><b>Executive Summary</b></u></h4>
                        
                          Rural distress has been the buzzword around which many policy narratives have been created and
                          debated to make way for interventions in the rural sector with mixed results, while we have been
                          able to make considerable progress on some of the economic parameters after independence, we
                          still have to deal with news of farmers’ suicides and starvation deaths that reflect the enormity of
                          the disconnect that exists just under the veneer of information revolution and economic growth;
                          the economic integration of large swathe of population has always been a challenge for a system
                          that fights with its own deficiencies at the ground level. While prognosis for economic growth
                          looks gloomy in the aftermath of Covid pandemic, many see a ray of hope in the rural sector to
                          give support to the mainstream economic activities by starting a virtuous cycle with demand
                          creation if agriculture sector succeeds in sustaining itself through this difficult phase. Therefore,
                          there cannot be more opportune times for interventions in the agriculture sector to leverage its
                          potential to reduce poverty and fuel the growth engines of the economy.
                          This work shows that the interventions at all levels of farming value chain have failed to show the
                          desired results for the lack of an integrated approach and efficient management at the ground level;
                          farmers, public and private institutions, industry, all work in silos with minimum direct linkages
                          which leaves little space for a planned, coordinated approach to create shared value in an
                          ecosystem that encourages innovation, entrepreneurial culture, and benefits of scale.The following
                          are some of the issues1
                          that we have kept in focus while preparing this report to find actionable
                          areas and model for interventions in the rural sector: -
                          <ul>

                            <li>• Low penetration of institutional credit</li>
                            <li>• Quality of inputs such as seeds, fertilizers, pesticides</li>
                            <li>• Small landholdings that make the investment in mechanization uneconomical</li>
                            <li>• Information asymmetry; access to real-time, region-specific information</li>
                            <li>• Lack of professional guidance, mentoring and monitoring</li>
                            <li>• Inadequate and inefficient post-harvest infrastructure</li>
                            <li>• Absence of professionally managed farmers’ enterprises</li>
                            <li>• Absence of direct institutional and industry connect</li>
                          </ul>
                      
                          Development of an integrated ecosystem including the integration of markets with a 360-degree
                          approach along the journey of an agriculture commodity from farm to fork with an in-built
                          mechanism for streamlining and leveraging ‘production and consumption’ through the application
                          of modern farm practices and management techniques is the long term solution for the problems
                          ailing the Indian agriculture sector.

                    <Container triggerText={triggerText} onSubmit={onSubmit} />

                  </p>
                </div>
              </div>
            </section>

           
          </div>

        </LayoutOne>
      </Fragment>

    );
  }
}

export default ResearchPaper2;