import React, { useState, useEffect } from "react";
import { animateScroll } from "react-scroll";
import { Link } from "react-router-dom"; 
 
const Footer = () => {
 
    const [scroll, setScroll] = useState(0);
    const [top, setTop] = useState(0);
  
    useEffect(() => {
      setTop(100);
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);
  
    const scrollToTop = () => {
      animateScroll.scrollToTop();
    };
  
    const handleScroll = () => {
      setScroll(window.scrollY);
    };

    return(
    <footer className="footer-section">
        <div className="container">
            <div className="row"> 
                {/*  Start:About  */}
                <div className="col-lg-3 col-sm-12">
                    <div className="widget">
                        <div className="footer_logo">
                            <img className="img-responsive" src="assets/images/logo1.jpg" alt="" />
                        </div>
                        <div className="footer_p">
                            <p className="footer_para">GRAMIIGO (Group for Rural and Agri Management to Ignite Inclusive Growth Opportunities)</p>
                        </div>
                        {/*
                        <div className="footer_socil">
                            <ul className="list-icons link-list footer_soc">
                                <li>
                                    <a href="//facebook.com" target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook"></i></a>
                                </li>
                                <li>
                                    <a href="//twitter.com" target="_blank" rel="noopener noreferrer"><i className="fa fa-twitter"></i></a>
                                </li>
                                <li>
                                    <a href="//facebook.com" target="_blank" rel="noopener noreferrer"><i className="fa fa-behance"></i></a>
                                </li>
                                <li>
                                    <a href="//instagram.com" target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram"></i></a>
                                </li>
                                <li>
                                    <a href="//pinterest.com" target="_blank" rel="noopener noreferrer"><i className="fa fa-pinterest"></i></a>
                                </li>
                            </ul>
                        </div>
    */}
                    </div>
                </div> 
                {/*  End:About  */}

                {/*  Start:Quick Link  */} 
                <div className="col-lg-3 col-sm-12">
                    <div className="widget quick_lnk">
                        <h5>Quick Link</h5>
                        <ul>
                            <li>
                                <Link to={process.env.PUBLIC_URL + "/home"}>Home</Link>
                            </li>
                            <li>
                                <Link to={process.env.PUBLIC_URL + "/gallery-two"}>Gallery</Link>
                            </li>
                            <li>
                                <Link to={process.env.PUBLIC_URL + "/partnership"}>Partnership Opportunities</Link>
                            </li>
                            <li>
                                <Link to={process.env.PUBLIC_URL + "/careers"}>Careers</Link>
                            </li>
                            <li>
                                <Link to={process.env.PUBLIC_URL + "/researchsupport"}>Research Room</Link>
                            </li>
                            <li>
                                <Link to={process.env.PUBLIC_URL + "/blog-two"}>Blog</Link>
                            </li>  
                        </ul>
                    </div>
                </div> 
                {/*  End:Quick Link  */}
 
                


                {/*  Start:Newsletter.  
                <div className="col-lg-3 col-sm-12">
                    <div className="widget">
                        <h5>Newsletter</h5> 
                        
                        <div className="news_letter_wrp">
                            <p>Subscribe our newsletter to get more update &  join to Gramiigo</p>
                            <form className="mailchimp"> 
                                <input className="email_field" type="text" name="email" id="subscriber-email" placeholder="Email Address" />
                                <button className="submit-contact" type="submit" id="subscribe-button">Subscribe</button>
                                <span className="subscription-success"> </span>
                                <span className="subscription-error"> </span>
                                <label className="subscription-label" htmlFor="subscriber-email"></label>
                            </form> 
                                                  
                        </div> 
                    </div>
                </div> */} 
                {/*  End:Start Subscribe  */} 

                {/*  Start:Latest post   */}
                <div className="col-lg-3 col-sm-12">
                    <div className="widget">
                        <h5>Communication</h5>
                        <ul className="footer_recent_blog">
                            <li>
                                Address
                                <br/>
                                <p>B-17, Geetanjali Enclave, Malviya Nagar, New Delhi-110017</p>
                            </li>
                            <li>
                                Phone no
                                <br/>
                                <p>+91 ***** *****</p>
                            </li>
                            <li>
                                Email
                                <br/>
                                <p>contact@gramiigo.org</p>
                            </li>
                            
                        </ul>

                        
                    </div>

                    

                </div> 
                {/*  End:Latest post  */}

                <button className={`scrollup ${scroll > top ? "show" : ""}`} onClick={() => scrollToTop()} >
                    <span className="icon-glyph-203"></span>
                </button>
            </div>
        </div> 
        {/*  Start:Subfooter  */}
        <div className="subfooter">
            <p>2022 © Copyright <Link to={process.env.PUBLIC_URL + "/"}>Gramiigo.</Link> All rights Reserved.</p>
        </div> 
        {/* End:Subfooter  */}
    </footer> 

        )
 
}

 
export default Footer;