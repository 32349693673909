import React, { Component, Fragment } from 'react';
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";
import Breadcrumb from "../components/Breadcrumb";
import ResearchForm from '../components/ResearchForm';


class ResearchSupport extends Component {

  render() {

    return (
      <Fragment>
        <MetaTags>
          <title>Gramiigo  | Research Support</title>
          <meta
            name="description"
            content="Organic Food React JS Template."
          />
        </MetaTags>

        <LayoutOne>

          <div className="contact-page">

            {/*====================  breadcrumb area ====================*/}

            <Breadcrumb title="Research Support" />

            {/*====================  End of breadcrumb area  ================*/}


            {/*====================  Contact Form  area  ====================*/}
            <section className="contact-section">
              <div className="container">
                <div className="base-header">
                  <small>Gramiigo</small>
                  <h3>Research Support</h3>
                </div>

                <div className="about_item">
                  <p>Peculiarities of rural sector have always intrigued and inspired researchers to look beyond the
                    obvious to understand the ‘whys and wherefores’. We operate in typical rural interiors to provide
                    the right setting to understand, study, and collect first-hand data which is a prerequisite for any
                    quality research work. In our endeavour to promote and facilitate research
                    work/training/internship in areas related to rural economy, sustainability, poverty alleviation,
                    women empowerment, etc, we extend support in terms of free stay at GRAMiigo centres for the
                    duration of their research work/training/internship. Besides, researchers can request for
                    additional facilities available at the centre if required in their research work.
                  </p>
                </div>

                <div>
                  <ResearchForm />
                </div>

              </div>
            </section>
          </div>

        </LayoutOne>
      </Fragment>

    );
  }
}

export default ResearchSupport;