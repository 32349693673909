import React,{Component, Fragment} from 'react';
import {Link} from 'react-router-dom'; 
import BlogSidebar from '../components/BlogSidebar'; 
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";
import Breadcrumb from "../components/Breadcrumb";
import { HashLink } from 'react-router-hash-link';

class SingleBlog extends Component{

 
    render(){

    const postTag = [
        { 
            name:'Food',  
            link: 'single-blog',
        },
        { 
            name:'Red',  
            link: 'single-blog',
        },
        { 
            name:'Grapes',  
            link: 'single-blog',
        },
        { 
            name:'Fresh',  
            link: 'single-blog',
        },
    ]
   
 
    const postTagMap = postTag.map((val, i) => {
        return(
            <Link to={`${val.link}`} key={i}> {val.name}</Link>
        )
    });


    return(
        <Fragment>
        <MetaTags>
          <title>Gramiigo | Blog</title>
          <meta
            name="description"
            content="Gramiigo"
          />
        </MetaTags>
        <LayoutOne>

	    <div className="single-blog">

            {/*====================  breadcrumb area ====================*/}

            <Breadcrumb title="Blog" />
            
            {/*====================  End of breadcrumb area  ====================*/} 

	        <div className="blog_container single_blog_container">
	            <div className="container"> 
	                <div className="row">     

                        <div className="col-lg-12 col-xs-12 blog-area">
                            <div className="blog-post-list">

                                <div id='blogstart' className="blog_wrp">
                                    <Link to="single-blog" >
                                        <img src="assets/images/blog/poly.jpg" alt="" />
                                    </Link>
                                    <div className="blog_info">
                                        
                                        
                                            <h4>How To Find And Afford Organic Food In Your Area</h4> 
                                        
                                        <p>It will not be an overstatement if we say that farmers are the most optimistic and big risk-takers when we consider the conditions and uncertainties they negotiate while operating in the agriculture sector. They are into an activity in which success or failure is highly dependent on the most uncertain factor—weather conditions. However, all is not well even if weather conditions work favorably for them in a particular season. They have no information on the demand in the market for their produce; the whole system is supply oriented which results in price-crash at regular intervals in case of surplus production; in a way, if farmers adopt modern techniques and produce more, their chances of having to face a situation of price-crash increase many folds, thereby, higher yield resulting in effective losses for farmers.  In market, the farmer is at the receiving end as commission agents decide the price at the time of the sale with no visibility on the price mechanism being adopted. Mostly, they are not in a position to hold their produce for a longer time for the lack of storage space or the requirement of immediate funds; and, incidentally, even if they have a storage facility, they don’t have real-time information on when to sell, and end up selling the produce at throwaway prices.
The whole ecosystem around a farmer has evolved in such a way that it inherently resists innovation and promotes the status-quo. There’s a well-established fully operational infrastructure in a particular region that caters to specific requirements of a specific crop grown there, it works like a comfort zone for a farmer—adoption of any new crop exposes that particular farmer to hazards of a new venture with no hands-on information/support system to see them through the initial phase of learning and experimentation, in the absence of real-time information any wrong judgment/step/miscalculation results in not only loss associated with that failed crop but the farmer has to start all over again to revert to the old crop pattern he had been following earlier. A break of one crop season results in disrupting the whole chain of activities to make things more complicated, therefore, most of the farmers continue to be with crop patterns they have been following for years and try to increase the production of the same crop by adopting new seed varieties and combinations of chemical fertilizers and different crop boosters marketed by various companies. No surprise when we see many water intensive crops such as sugarcane and paddy (rice) being grown in areas where groundwater levels have rung alarm bells to signal scarcity situation even for drinking water in very near future.
</p> 

                                        
                                           
                                            <ol>
                                                <li>
                                                Upfront investment, subsidies are provided once the project becomes operational; not something to enthuse a small farmer
                                                </li>
                                                <li>
                                                Uncertainties about the returns and future of the market	
 
                                                </li>
                                                <li>
                                                Requirement of trained labour force 
                                                </li>
                                                <li>
                                                Lack of conviction in new ideas (Many new techniques promoted by the government fail the test of time because of some practical issues— Failure of biogas plants, which was once promoted vigorously by the government, is the case in point—which breeds a sense of skepticism about the success of any new technique or scheme till it reaches a mass adoption level)
                                                </li>
                                                
                                                </ol>

                                                <p>
                                                Many wonder why initiatives like e-NAM fail to take off or deliver the desired result in agriculture sector. Though, a move in the right direction the initiatives like eNAM fail to address the issues at ground level; for, a big chunk of Indian farmers consists of small and marginal farmers; they lack the capacity to transport, hold or bargain for a right price. Majority of them end up selling their produce to intermediaries who, in turn, transport it to APMC mandis or open market in bulk quantity for sale. Farmers prefer to settle for a bit lower price at farmgate than to send directly to mandis for numerous logistics issues involved 

Furthermore, eNAM is an online platform to work as a common marketplace for buyers and sellers for agriculture produce; it’s a double loop marketplace whose success is interdependent on the number of buyers and sellers available on the platform.

                                                </p>

<div style={{textAlign : "center"}}>
<img src='assets/images/blog/poly1.jpg'  style={{ width: "500px"}}  />
</div>
<p>Traders are not enthused by this mode when they don’t see any compelling reason to come on this platform, farmers too prefer to rely on face-to-face transactions to sell their produce. Traders and commission agents prefer traditional mode of business as this helps them benefit from the established network of farmers and enables them to use informal financial channels for transactions. Therefore, despite everything having been introduced in the agriculture sector to bring about some rapid changes, things move with their own speed or not move at all in the want of a bottom-up approach. 
</p>
                                       
                                     {/*   <div className="post_tags">
                                            <h5> Tags</h5>
                                            {postTagMap} 
                                        </div> 
                                        <div className="post_share footer_socil"> 
                                            <ul className="list-icons link-list footer_soc">
                                                <li>
                                                    <Link to={process.env.PUBLIC_URL + "/single-blog"}><i className="fa fa-facebook"></i></Link>
                                                </li>
                                                <li>
                                                    <Link to={process.env.PUBLIC_URL + "/single-blog"}><i className="fa fa-twitter"></i></Link>
                                                </li>
                                                <li>
                                                    <Link to={process.env.PUBLIC_URL + "/single-blog"}><i className="fa fa-behance"></i></Link>
                                                </li>
                                                <li>
                                                    <Link to={process.env.PUBLIC_URL + "/single-blog"}><i className="fa fa-instagram"></i></Link>
                                                </li>
                                                <li>
                                                    <Link to={process.env.PUBLIC_URL + "/single-blog"}><i className="fa fa-pinterest"></i></Link>
                                                </li>
                                            </ul>
                                        </div>  */}

                                    </div>
                                </div>
                            </div> 
                            

                            <div className="post-option">
                                {/*<div className="pull-left">
                                    <Link to={process.env.PUBLIC_URL + "/single-blog"} className="prev-post"><span className="arrow-icon icon-glyph-205"></span> &nbsp;</Link>
                                    </div>*/}
                                <span className="middle-icon">
                                    <Link to={process.env.PUBLIC_URL + "/blog-two"} className="icon-glyph-99"> </Link>
                                    </span>
                                <div className="pull-right">
                                    <HashLink to={process.env.PUBLIC_URL + "/blog2#blogstart"} className="next-post"> &nbsp;<span className="arrow-icon icon-glyph-204"></span></HashLink>
                                </div>
                            </div>
 
                            {/*  comments list */} 
                             
                            
                            {/*  comments list */} 

 
                            {/*  comments box */} 
                            
                            {/*  End: comment box */}  
                        </div>
                        {/* Blog Area */}


                        {/*  Widget Area */} 
                        
                        
 
                        {/* End: Widget Area */} 

	                </div> 
	            </div> 
	        </div>


		</div> 

        </LayoutOne>
        </Fragment>
        
        )

    }
}

 
export default SingleBlog;