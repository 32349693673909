import React from "react";
import {Link} from 'react-router-dom';
import {Navbar, Nav} from 'react-bootstrap'; 
import MobileMenu from "../components/mobile-menu/MobileMenu";
import MobileBtn from "../components/mobile-menu/MobileBtn";


const Menu = () => {

      const triggerSearch = () => {
        const offcanvasMobileMenu = document.querySelector(".search_icon_inr");
        offcanvasMobileMenu.classList.toggle("active");
      };

    return (

<div className="menu_area">	
    {/* Start: header navigation */}
    <div className="navigation">
        <div className="container"> 
                <div className="logo">
                    <Link to={process.env.PUBLIC_URL + "/"}> 
                    	<img src="assets/images/logo1.jpg" alt="" />
                    </Link>
                </div>

                <div className="meun_wrp">
                    <Navbar expand="lg" sticky="top" id="navigation">  
                        <Nav className="mr-auto">
                            <ul>
                                <li className="active">
                                <Link to={process.env.PUBLIC_URL + "/"}>Home </Link></li>
                                
                                <li className="has-sub"><Link to="#/">Gramiigo</Link>
                                    <ul>   
                                    <li><Link to="about">About Us</Link>
                                        </li>
                                        {/*<li><a href="localhost:3000/login">Join Gramiigo</a>
                                        </li>*/}
                                        <li><Link to="upcomingproject">Upcoming projects</Link>
                                        </li>
                                     {/*   <li><Link to="#"> Notice Board</Link>
                                        </li> */}
                                        <li><Link to="gallery-two">Gallery</Link>
                                        </li>
                                        {/*<li><Link to="#">Buy online</Link>
                                        </li>*/}
                                        {/*<li><Link to="#">Our products</Link>
                                        </li>*/}
                                    </ul>
                                </li>

                                <li><Link to="partnership">Partnership Opportunities</Link>
                                </li>

                                <li><Link to="careers">Careers</Link>
                                </li>

                                <li className="has-sub"><Link to="researchsupport">Research Room</Link>
                                    <ul>
                                        <li><Link to="researchsupport">Research Support</Link> </li>
                                        <li><Link to="researchpaper">Research Papers/ Reports</Link> </li>
                            
                                    </ul>
                                </li>
                               
                                <li><Link to="blog-two">Blog</Link>
                                </li>
                               {/*
                                <li className="has-sub"><Link to="#/">Pages</Link>
                                    <ul>
                                        <li><Link to="gallery">Gallery Page</Link>
                                        </li>
                                        <li><Link to="gallery-two">Gallery Two</Link>
                                        </li>
                                        <li><Link to="services">Services Page</Link>
                                        </li>
                                        <li><Link to="single-services">Services Details</Link>
                                        </li>
                                        <li><Link to="about">About Page</Link>
                                        </li>
                                        <li><Link to="team">Team</Link>
                                        </li>
                                        <li><Link to="shop">Shop</Link>
                                        </li>
                                        <li><Link to="single-shop">Shop Details</Link></li>                                    
                                        <li><Link to="cart">Shop Cart</Link></li>                                    
                                        <li><Link to="checkout">Checkout</Link></li>  
                                    </ul>
                                </li>
    */}
                                
                                
                                
                            </ul>
                        </Nav> 
                    </Navbar>
                </div>
                

                {/* Mobile Menu */}

                <MobileBtn /> 

                <MobileMenu />

                {/* End:  Mobile Menu */}


                {/* Start: Cart  */}
                <div className="header_cart">
                    <ul>
                      {/*   <li className="header_search">
                            <Link to="#" onClick={() => triggerSearch()} className="cart-toggler search_icon"><i className="icon-glyph-16"></i></Link>
 
                            <div className="search_icon_inr">
                                <form action="#" method="POST">
                                    <div>
                                        <input placeholder="Search" type="text" />
                                        <button className="btn-search" type="submit">
                                            <i className="fa fa-search"></i>
                                        </button>
                                    </div>
                                </form>
                            </div> 
                        </li> */}
                        {/*<li className="header_cart_icon">
                            <Link to="cart"><i className="icon-glyph-13"></i><span className="number_cart">0</span></Link>
                    </li>*/}
                    </ul>
                </div>
                 {/* End: Cart  */}
 
        </div>
        {/* container */} 
    </div>
    {/* End: header navigation */}

 
</div>
 
    );
  } 
  
 
export default Menu;
