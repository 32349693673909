import React,{Component, Fragment} from 'react'; 
import ServiceSidebar from '../../components/ServiceSidebar'; 
import MetaTags from "react-meta-tags";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../components/Breadcrumb";


class InitiativesGrammiigoRutor extends Component{
	render(){

	const serviceTopArray = [
		{
			image:'5.png',
            title:"GRAMiigo Rutor (Rural Tourism)",
			desPartOne:"A village is not just about its idyllic setting with an abundance of natural beauty—a place inhabited by some simple and affable people; it is also about rediscovering the rhythm of life while being among the people who live life to the fullest despite all the hardships being part of it.",
            desPartTwo:"‘Gramiigo Rutor’ is an initiative to rekindle those memories of childhood by connecting urban life to the rural heartland while giving people an opportunity to play an indirect role in the efforts to bring about some positive changes in the lives of those who feed and build this nation, and form the core of the economy but live on the margins of our society", 
		},
	]
 
    const serviceBottomArray = [
        {
            icon:'check.png',
            title:"100% Satisfaction",
            des:"Experienced staff read nal Experienced sto help you full Prond Experienced eaelp you help anytime you",  
        },
        {
            icon:'check.png',
            title:"Experienced Staff",
            des:"Experienced staff read nal Experienced sto help you full Prond Experienced eaelp you help anytime you",  
        },
    ]

    const serviceToptMap = serviceTopArray.map((val, i) => {
        return(
		    <div className="single_service_left" key={i}> 
                <img src={`assets/images/initiative/${val.image}`} alt="" />
                <h4>{val.title}</h4>
                <p>{val.desPartOne}</p>
                <p>{val.desPartTwo}</p>
            </div>
        )
    });

    const serviceBottomtMap = serviceBottomArray.map((val, i) => {
        return(
            <div className="col-md-6 col-sm-12" key={i}>
                <div className="sing_service_item">
                    <div className="icon-serv"> 
                        <img src={`assets/images/${val.icon}`} alt="" />
                    </div>
                    <h4>{val.title}</h4>
                    <p>{val.des}</p>
                </div>
            </div>
        )
    });


	return(
        <Fragment>
        <MetaTags>
          <title>Gramiigo | Rutor</title>
          <meta
            name="description"
            content="Gramiigo"
          />
        </MetaTags>
        <LayoutOne>

       <div className="single-services-page">

            {/*====================  breadcrumb area ====================*/}

            <Breadcrumb title="GRAMiigo Rutor" />
            
            {/*====================  End of breadcrumb area  ====================*/} 


            {/*====================  Single Services area ====================*/}
            <div className="single_service">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-sm-12">

                            {serviceToptMap}

                            
                        </div>

                        <div className="col-md-4 col-sm-12">

                            <ServiceSidebar />
                            
                        </div>

                    </div>
                </div>
            </div>
             {/*====================  End Single Services area ====================*/} 
        </div>
        
          </LayoutOne>
        </Fragment>

 	)

	}
}

 
export default InitiativesGrammiigoRutor;