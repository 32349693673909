import React,{Component, Fragment} from 'react'; 
import ServiceSidebar from '../../components/ServiceSidebar'; 
import MetaTags from "react-meta-tags";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../components/Breadcrumb";


class InitiativesGrammiigoFarms extends Component{
	render(){

	const serviceTopArray = [
		{
			image:'3.jpg',
            title:"GRAMiigo Farms",
			desPartOne:`Gramiigo has embarked on a mission to bring about a sea change in the ground realities in the rural sector. This implies a role that goes beyond the space defined for a facilitator of a change or a promotor of a cause, one must act as a harbinger of change by paving the way for others to follow. Implementation of this research-based project to study its all-pros and cons over a period is only possible by being one with the farming community with a replicable parallel structure.
            Gramiigo farms are the platforms for experimentation and research to try out technological
            advancements and novel practices in the agriculture sector before we offer them as affordable
            solutions to farmers; AI-based precision farming and soilless techniques for controlled-
            environment agriculture are the areas that need further development to bring tech-based solutions
            closer to small and marginal farmers.`,
		},
	]
 
    const serviceBottomArray = [
        {
            icon:'check.png',
            title:"100% Satisfaction",
            des:"Experienced staff read nal Experienced sto help you full Prond Experienced eaelp you help anytime you",  
        },
        {
            icon:'check.png',
            title:"Experienced Staff",
            des:"Experienced staff read nal Experienced sto help you full Prond Experienced eaelp you help anytime you",  
        },
    ]

    const serviceToptMap = serviceTopArray.map((val, i) => {
        return(
		    <div className="single_service_left" key={i}> 
                <img src={`assets/images/initiative/${val.image}`} alt="" />
                <h4>{val.title}</h4>
                <p>{val.desPartOne}</p>
            </div>
        )
    });

    const serviceBottomtMap = serviceBottomArray.map((val, i) => {
        return(
            <div className="col-md-6 col-sm-12" key={i}>
                <div className="sing_service_item">
                    <div className="icon-serv"> 
                        <img src={`assets/images/${val.icon}`} alt="" />
                    </div>
                    <h4>{val.title}</h4>
                    <p>{val.des}</p>
                </div>
            </div>
        )
    });


	return(
        <Fragment>
        <MetaTags>
          <title>Gramiigo | Gramiigo Farms</title>
          <meta
            name="description"
            content="Gramiigo"
          />
        </MetaTags>
        <LayoutOne>

       <div className="single-services-page">

            {/*====================  breadcrumb area ====================*/}

            <Breadcrumb title="GRAMiigo Farms" />
            
            {/*====================  End of breadcrumb area  ====================*/} 


            {/*====================  Single Services area ====================*/}
            <div className="single_service">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-sm-12">

                            {serviceToptMap}

                            
                        </div>

                        <div className="col-md-4 col-sm-12">

                            <ServiceSidebar />
                            
                        </div>

                    </div>
                </div>
            </div>
             {/*====================  End Single Services area ====================*/} 
        </div>
        
          </LayoutOne>
        </Fragment>

 	)

	}
}

 
export default InitiativesGrammiigoFarms;