 
import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import { useState } from "react";
import Modal from './card-comp/Modal'
import Modal1 from './card-comp/Modal1'
import Modal2 from './card-comp/Modal2'
import Modal3 from './card-comp/Modal3'

function ProductsCard() {

    

    const productArray = [
        {
            image:'1.png'
        
        },
         {
            image:'2.png'
            
        },
        {
            image:'3.png'
            
        },
        {
            image:'4.png'
           
        },
    ]
    
    const [modalOpen, setModalOpen] = useState(false);
    const [modalOpen1, setModalOpen1] = useState(false);
    const [modalOpen2, setModalOpen2] = useState(false);
    const [modalOpen3, setModalOpen3] = useState(false);

    return( 
        
		<section className="product-section">
		    <div className="container">
        {/*    <div className="base-header">
		            <small> Our Featured Products</small>  
		            <h3> Organic products</h3> 
    </div>*/}
		        <div className="row">

                <div className="col-md-3 col-sm-12" > 
                <div className="product_wrp" onClick={() => { setModalOpen(true);}} >
                    <div className="product_img" >  
                        <img src="assets/images/cards/1.png"  alt="product" />
                        
                    </div>
                    

                    
                    <div className="project_view">
                        
                    </div>
                </div>
            </div>

            <div className="col-md-3 col-sm-12" > 
                <div className="product_wrp" onClick={() => { setModalOpen1(true);}} >
                    <div className="product_img" >  
                        <img src="assets/images/cards/2.png"  alt="product" />
                        
                    </div>
                    

                    
                    <div className="project_view">
                        
                    </div>
                </div>
            </div>

            <div className="col-md-3 col-sm-12" > 
                <div className="product_wrp" onClick={() => { setModalOpen2(true);}} >
                    <div className="product_img" >  
                        <img src="assets/images/cards/3.png"  alt="product" />
                        
                    </div>
                    

                    
                    <div className="project_view">
                        
                    </div>
                </div>
            </div>

            <div className="col-md-3 col-sm-12" > 
                <div className="product_wrp" onClick={() => { setModalOpen3(true);}} >
                    <div className="product_img" >  
                        <img src="assets/images/cards/4.png"  alt="product" />
                        
                    </div>
                    

                    
                    <div className="project_view">
                        
                    </div>
                </div>
            </div>

            

            {modalOpen && <Modal setOpenModal={setModalOpen} />}
            {modalOpen1 && <Modal1 setOpenModal={setModalOpen1} />}
            {modalOpen2 && <Modal2 setOpenModal={setModalOpen2} />}
            {modalOpen3 && <Modal3 setOpenModal={setModalOpen3} />}
		        </div>
		    </div>
		</section>

        )

    }


 
export default ProductsCard;