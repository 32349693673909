import React from "react";


function Modal3({ setOpenModal }) {
  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="titleCloseBtn">
          <button
            onClick={() => {
              setOpenModal(false);
            }}
          >
            X
          </button>
        </div>
        
        <div className="">
        <div className="product_img" style={{textAlign: "center"}}>  
                        <img src="assets/images/homecard/3.png"  alt="product" height={'250px'}/>
                        
                    </div>
                    <br></br>
          <p> GRAMiigo has been formed with a vision to develop a self-sustainable model to bring about a positive change through interventions that are aimed at streamlining and aggregating the economic activities of rural India; every penny you spend with GRAMiigo is going to be the building block for a prosperous future of the most marginalized</p>
        </div>
        
      </div>
    </div>
  );
}

export default Modal3;
