import React from "react";


function Modal3({ setOpenModal }) {
  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="titleCloseBtn">
          <button
            onClick={() => {
              setOpenModal(false);
            }}
          >
            X
          </button>
        </div>
        
        <div className="">
        <div className="product_img" style={{textAlign: "center"}}>  
                        <img src="assets/images/cards/4.png"  alt="product" />
                        
                    </div>
          <p> ‘Development in Harmony with Nature’ is the thread that binds our present to a better and sustainable future. Implications of global warming are more acute for the rural sector as its livelihood predominantly depends on the agriculture sector in India. From encouraging sustainable practices in agriculture to taking care of the environment and destitute animals, we stand by our commitment of caring for all</p>
        </div>
        
      </div>
    </div>
  );
}

export default Modal3;
