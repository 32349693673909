import React, { Component, Fragment } from 'react';
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";
import Breadcrumb from "../components/Breadcrumb";

import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBCardHeader, MDBCardFooter, MDBBtn } from 'mdb-react-ui-kit';

import { Container } from '../components/ResearchContainer'

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';


class ResearchPaper extends Component {

    

  render() {

    const triggerText = 'Read More';
  const onSubmit = (event) => {
    event.preventDefault(event);
    console.log(event.target.name.value);
    console.log(event.target.email.value);
  };
    return (
      <Fragment>
        <MetaTags>
          <title>Gramiigo | Research Paper</title>
          <meta
            name="description"
            content="Organic Food React JS Template."
          />
        </MetaTags>

        <LayoutOne>

          <div className="contact-page">

            {/*====================  breadcrumb area ====================*/}
            <Breadcrumb title="Research Papers" />
            {/*====================  End of breadcrumb area  ================*/}
            {/*====================  Contact Form  area  ====================*/}
            <section className="contact-section">
              <div className="container">

              <div className="base-header">
                  <small><h3>Gramiigo</h3></small>
                </div>

                <MDBCard  className='w-100'>
                <MDBCardBody>
                  <MDBCardTitle> <h4><b>Economics of Long Queues:</b><br/>
                        <h6>An Analysis of Relationship Between Potato Farmers and Cold-Store Industry</h6>
                    </h4></MDBCardTitle>
                  <MDBCardText> <h4><u><b>Abstract</b></u></h4>
                    Long queues in front of every cold store in the potato belt of Agra (India) has intrigued
                    many observers — a scenario that builds up every year at the peak of harvesting season
                    which turns into a situation of never-ending queues in the year of bumper crop. The 
                    paper tries to find the reasons which prevent the cold store industry to find a solution 
                    to ameliorate the situation and find a solution. This work finds how different economic 
                    factors and the monopolistically competitive market conditions add to market inefficiencies 
                    which lead to formation of these queues and a situation in which firms do not compete with 
                    each other on better queue management system.<br/>
                </MDBCardText>
                  <MDBBtn onClick={()=> window.open("researchpaper1", "_blank")} >View Paper</MDBBtn>
                  {/* <button onClick={()=> window.open("researchpaper1", "_blank")}>Click Here</button> */}
                </MDBCardBody>
              </MDBCard>

              <MDBCard className='w-100'>
                <MDBCardBody>
                  <MDBCardTitle><h4><b>Optimization of Farming Value Chain: Actionable Areas for Interventions</b>
                    </h4></MDBCardTitle>
                  <MDBCardText>
                    <h4><u><b>Abstract</b></u></h4> 
                        Interventions at all levels of farming value chain have failed to show the desired results 
                        for the lack of an integrated approach and efficient management at the ground level; 
                        farmers, public and private institutions, industry, all work in silos with minimum direct 
                        linkages which leaves little space for a planned, coordinated approach to create shared 
                        value in an ecosystem that encourages innovation, entrepreneurial culture, and benefits 
                        of scale.

                        <br/><u><b>Keywords: </b></u>Indian Agriculture Sector, Sustainability, Post- Harvest Management, 
                                  Value Addition, Micro Finance, Information Asymmetry
                    </MDBCardText>
                  <MDBBtn onClick={()=> window.open("researchpaper2", "_blank")}>View Paper</MDBBtn>
                </MDBCardBody>
              </MDBCard>

              <MDBCard className='w-100'>
                <MDBCardBody>
                  <MDBCardTitle>
                    <h4><b>How to Achieve 'Sustainable Growth' for Rural India</b></h4>
                  </MDBCardTitle>
                  
                  <MDBCardText>
                    <h4><u><b>
                        Abstract
                    </b></u></h4>
                        Non-inclusive growth, faulty implementation and limited reach of various welfare schemes, lack of proper 
                        monitoring system at ground level, absence of functioning social infrastructure, low penetration of 
                        institutionalized credit, peculiarities of rural set-up are the reasons for less than optimal growth in 
                        the rural sector. While rural India presents immense growth potential, challenges in the agriculture sector 
                        drag it down; from structural and market deficiencies to issues of the water crisis, the Indian agriculture 
                        sector is faced with challenges that put a question mark on the sustainability of the current farm practices.
                  </MDBCardText>
                  <MDBBtn onClick={()=> window.open("researchpaper3", "_blank")}>View Paper</MDBBtn>
                </MDBCardBody>
              </MDBCard>
    
              </div>
            </section>

           
          </div>

        </LayoutOne>
      </Fragment>

    );
  }
}

export default ResearchPaper;