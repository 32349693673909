import React,{Component, Fragment} from 'react'; 
import ServiceSidebar from '../../components/ServiceSidebar'; 
import MetaTags from "react-meta-tags";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../components/Breadcrumb";


class InitiativesGrammiigoOrganics extends Component{
	render(){

	const serviceTopArray = [
		{
			image:'2.jpg',
            title:"GRAMiigo Organics",
			desPartOne:`Harmful long-term effects of the use of chemical fertilizers and pesticides in agriculture on
            human health and the environment are well known and, thereby, inclination towards healthy
            foods and organic products has generated a new market segment for agriculture products.
            However, the long gestation period for the development of an ecosystem for organic farming,
            lack of marketing support, low visibility on the viability of organic farming and its long-term
            accrual of benefits to farmers, absence of institutional players in this domain at the ground level
            are some of the reasons that have kept farmers stick to the farm practices which promote
            excessive use of chemical fertilizers and pesticides as a method to achieve higher production
            levels.`,
            desPartTwo:`Gramiigo seeks to change this scenario by being instrumental in bringing farmers towards the
            adoption of sustainable farm practices; creating a complete ecosystem to support organic farming
            is one of the objectives which Gramiigo seeks to achieve by being on the ground with farmers at
            every stage of farming value chain so that the scales and quality in output can be reached to
            integrate farmers into the mainstream of processing industry; production of vermicompost on an
            industrial scale by Gramiigo is the step in the same direction`, 
		},
	]
 
    const serviceBottomArray = [
        {
            icon:'check.png',
            title:"100% Satisfaction",
            des:"Experienced staff read nal Experienced sto help you full Prond Experienced eaelp you help anytime you",  
        },
        {
            icon:'check.png',
            title:"Experienced Staff",
            des:"Experienced staff read nal Experienced sto help you full Prond Experienced eaelp you help anytime you",  
        },
    ]

    const serviceToptMap = serviceTopArray.map((val, i) => {
        return(
		    <div className="single_service_left" key={i}> 
                <img src={`assets/images/initiative/${val.image}`} alt="" />
                <h4>{val.title}</h4>
                <p>{val.desPartOne}</p>
                <p>{val.desPartTwo}</p>
            </div>
        )
    });

    const serviceBottomtMap = serviceBottomArray.map((val, i) => {
        return(
            <div className="col-md-6 col-sm-12" key={i}>
                <div className="sing_service_item">
                    <div className="icon-serv"> 
                        <img src={`assets/images/${val.icon}`} alt="" />
                    </div>
                    <h4>{val.title}</h4>
                    <p>{val.des}</p>
                </div>
            </div>
        )
    });


	return(
        <Fragment>
        <MetaTags>
          <title>Gramiigo | Gramiigo Organics</title>
          <meta
            name="description"
            content="Gramiigo"
          />
        </MetaTags>
        <LayoutOne>

       <div className="single-services-page">

            {/*====================  breadcrumb area ====================*/}

            <Breadcrumb title="GRAMiigo Organics" />
            
            {/*====================  End of breadcrumb area  ====================*/} 


            {/*====================  Single Services area ====================*/}
            <div className="single_service">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-sm-12">

                            {serviceToptMap}

                            
                        </div>

                        <div className="col-md-4 col-sm-12">

                            <ServiceSidebar />
                            
                        </div>

                    </div>
                </div>
            </div>
             {/*====================  End Single Services area ====================*/} 
        </div>
        
          </LayoutOne>
        </Fragment>

 	)

	}
}

 
export default InitiativesGrammiigoOrganics;