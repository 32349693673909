import React,{Component} from 'react';
import {Link} from 'react-router-dom'; 

class ServiceSidebar extends Component{
	render(){

	const serviceListArray = [
		{
			serviceTitle:"GRAMiigo Seeds",
			sidebarLink:"initiatives-Grammiigo-seeds",  
		},
		{
			serviceTitle:"GRAMiigo Organics",
			sidebarLink:"initiatives-Grammiigo-organics",  
		},
		{
			serviceTitle:"GRAMiigo Farms",
			sidebarLink:"initiatives-Grammiigo-farms",  
		},
		{
			serviceTitle:"GRAMiigo Traimp (Tractor and Implements)",
			sidebarLink:"initiatives-Grammiigo-traimp",  
		},
		{
			serviceTitle:"GRAMiigo Rutor (Rural Tourism)",
			sidebarLink:"initiatives-Grammiigo-rutor",  
		},
		{
			serviceTitle:"GRAMiigo Care",
			sidebarLink:"initiatives-Grammiigo-care",  
		},
	]

 

    const serviceListMap = serviceListArray.map((val, i) => {
        return(
			<li key={i}><Link to={`${val.sidebarLink}`}>{val.serviceTitle}</Link></li>
        )
    });

	return(
		<div className="single_service_right">
            <div className="single_service_cat">
                <ul>
                    {serviceListMap}
                </ul>
            </div>
            <div className="service_contact"> 
                <h4>Contact </h4>
                <div className="serv_contact_wrp">
                    <div className="single-contact-info">
                        <i className="fa fa-phone"></i>
                        <p>+91 ***** *****</p>
                    </div>
                    <div className="single-contact-info">
                        <i className="fa fa-envelope"></i>
                        <p>contact@gramiigo.org</p>
                    </div>
                    <div className="single-contact-info">
                        <i className="fa fa-globe"></i>
                        <p>B-17, Geetanjali Enclave, Malviya Nagar, New Delhi-110017</p>
                    </div> 
                </div>
            </div>
            
        </div>
 	)

	}
}

 
export default ServiceSidebar;