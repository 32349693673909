import React from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import { useState } from "react";

var selectedImgSrc = "";
var selectedImgTitle = "";
function Modal1({ setOpenModal }) {
    return (
        <div className="modalBackground">
            <div className="modalContainer1">
                <div className="titleCloseBtn">
                    <button
                        onClick={() => {
                            setOpenModal(false);
                        }}
                    >
                        X
                    </button>
                </div>
                <div className="product_img">
                    <div className="item" style={{ textAlign: "center" }}>
                        <img className="imgSrcClass" src={`assets/images/${selectedImgSrc}`} alt="Gallery Image" />
                    </div>
                    <h4 style={{ textAlign: "center" }}>{selectedImgTitle}</h4>
                </div>
            </div>
        </div>
    );
}

function GalleryContentSupport() {
    const [modalOpen1, setModalOpen1] = useState(false);
    var settings = {
        autoplay: true,
        autoplaySpeed: 4000,
        dots: false,
        arrows: false,
        infinite: true,
        centerMode: true,
        centerPadding: '200px',
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '80px',
                    arrows: false,
                    dots: false,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    centerPadding: '40px',
                    centerMode: false,
                    arrows: false,
                    dots: false
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerMode: false,
                    arrows: false,
                    dots: false
                }
            },
            {
                breakpoint: 580,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                    arrows: false,
                    dots: false
                }
            },

        ]
    };

    let imageGalleryData = [
        {
            image: 'gallery-1.jpg',
            title: 'GRAMiigo Office',
            category: 'Research and development in agriculture'
        },
        {
            image: 'gallery-2.jpg',
            title: 'Lake',
            category: 'Rainwater Harvesting'
        },
        {
            image: 'gallery-3.jpg',
            title: 'Hi Tech Poly House',
            category: 'Research and development in agriculture'
        },
        {
            image: 'gallery-4.jpg',
            title: 'Bottlegourd farm',
            category: 'Precision Farming'
        },
        {
            image: 'gallery-5.jpg',
            title: 'Dairy Farm',
            category: 'Gramiigo Care'
        },
        {
            image: 'gallery-6.jpg',
            title: 'louging House',
            category: 'GRAMiigo Rutor (Rural Tourism)'
        },
        {
            image: 'gallery-7.jpg',
            title: 'Our Farm',
            category: 'Precision Farming'
        },
        {
            image: 'gallery-8.jpg',
            title: 'Dairy Farm',
            category: 'Gramiigo Care'
        },
        {
            image: 'gallery-9.jpg',
            title: 'Our Team',
            category: 'Field work'
        },
        {
            image: 'gallery-10.jpg',
            title: 'Our Farm',
            category: 'Precision Farming'
        },
        {
            image: 'gallery-11.jpg',
            title: 'Our team',
            category: 'Office'
        },
        {
            image: 'gallery-12.jpg',
            title: 'Hi Tech Poly House',
            category: 'Research and development in agriculture'
        },
    ];

    let ImageGalleryDataList = imageGalleryData.map((val, i) => {
        return (
            // setting onDoubleClick interaction here, as while scrolling images  
            <div className="col-md-12 swiper-slide service-gallery__single-slide" key={i}
                onClick={() => {
                    selectedImgSrc = val.image;
                    selectedImgTitle = val.title;
                    setModalOpen1(true);
                }}
            >
                <div className="project-item item">
                    <div className="project_slide_img item">
                        <img src={`assets/images/${val.image}`} alt="Gallery Image" />
                    </div>
                    <div className="project_text">
                        <h4>{val.title}</h4>
                        <span className="project_link">{val.category}</span>
                    </div>
                    <div className="project_view">
                        {/*add interactions if required in upcoming changes here */}
                        
                    </div>
                </div>
            </div>
        )
    });

    return (

        <section className="project-section">
            <div className="container-fluid">
                <div className="project_list_one">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-sm-12">
                                <div className="base-header base_header_left">
                                    <h3> Gallery </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="project_slider_one">
                        <Slider {...settings}>

                            {ImageGalleryDataList}

                        </Slider>
                    </div>
                    <div className="project_btn text-center"><Link to="gallery-two" className="more-link">View More</Link></div>
                </div>
            </div>

            {modalOpen1 && <Modal1 setOpenModal={setModalOpen1} />}
        </section>
    );
}

export default GalleryContentSupport;
