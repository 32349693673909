import React from "react";
import { BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
//import { HashRouter as Router, Switch, Route, Link} from "react-router-dom";
import ScrollToTop from "./helpers/ScrollToTop";
import Home from "./pages/Home"; 
import About from "./pages/About"; 
import Services from "./pages/Services"; 
import Gallery from "./pages/Gallery"; 
import GalleryTwo from "./pages/GalleryTwo"; 
import Contact from "./pages/Contact"; 
import SingleService from "./pages/SingleService"; 
import Team from "./pages/Team";  
import Shop from "./pages/Shop"; 
import SingleShop from "./pages/SingleShop"; 
import BlogPageTwo from "./pages/BlogPageTwo"; 
import BlogPageOne from "./pages/BlogPageOne"; 
import SingleBlog from "./pages/SingleBlog"; 
import SingleBlog2 from "./pages/SingleBlog2"; 
import Cart from "./pages/Cart"; 
import Checkout from "./pages/Checkout"; 
import NotFound from "./pages/NotFound"; 
import UpcomingP from "./pages/Upcoming"
import Partnership from "./pages/Partnership"
import Careers from "./pages/Careers"
import InitiativesGrammiigoSeeds from "./pages/initiatives/InitiativesGrammiigoSeeds"; 
import InitiativesGrammiigoOrganics from "./pages/initiatives/InitiativesGrammiigoOrganics"; 
import InitiativesGrammiigoTraimp from "./pages/initiatives/InitiativesGrammiigoTraimp"; 
import InitiativesGrammiigoFarms from "./pages/initiatives/InitiativesGrammiigoFarms"; 
import InitiativesGrammiigoRutor from "./pages/initiatives/InitiativesGrammiigoRutor"; 
import InitiativesGrammiigoCare from "./pages/initiatives/InitiativesGrammiigoCare"; 
import ResearchSupport from "./pages/ResearchSupport";
import ResearchPaper from "./pages/ResearchPaper";
import ResearchPaper1 from "./pages/ResearchPaper1";
import ResearchPaper2 from "./pages/ResearchPaper2";
import ResearchPaper3 from "./pages/ResearchPaper3";


function App() {
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/"}`}
            component={Home}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/home"}`}
            component={Home}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/about"}`}
            component={About}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/upcomingproject"}`}
            component={UpcomingP}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/partnership"}`}
            component={Partnership}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/careers"}`}
            component={Careers}
          />
           <Route
            path={`${process.env.PUBLIC_URL + "/initiatives-Grammiigo-seeds"}`}
            component={InitiativesGrammiigoSeeds}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/initiatives-Grammiigo-organics"}`}
            component={InitiativesGrammiigoOrganics}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/initiatives-Grammiigo-farms"}`}
            component={InitiativesGrammiigoFarms}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/initiatives-Grammiigo-traimp"}`}
            component={InitiativesGrammiigoTraimp}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/initiatives-Grammiigo-rutor"}`}
            component={InitiativesGrammiigoRutor}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/initiatives-Grammiigo-care"}`}
            component={InitiativesGrammiigoCare}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/services"}`}
            component={Services}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/gallery"}`}
            component={Gallery}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/gallery-two"}`}
            component={GalleryTwo}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/contact"}`}
            component={Contact}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/single-services"}`}
            component={SingleService}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/team"}`}
            component={Team}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/shop"}`}
            component={Shop}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/single-shop"}`}
            component={SingleShop}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/blog"}`}
            component={BlogPageOne}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/blog-two"}`}
            component={BlogPageTwo}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/single-blog"}`}
            component={SingleBlog}
          />

          <Route
            path={`${process.env.PUBLIC_URL + "/blog2"}`}
            component={SingleBlog2}
          />

          <Route
            path={`${process.env.PUBLIC_URL + "/single-blog"}`}
            component={SingleService}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/researchsupport"}`}
            component={ResearchSupport}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/researchpaper"}`}
            component={ResearchPaper}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/researchpaper1"}`}
            component={ResearchPaper1}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/researchpaper2"}`}
            component={ResearchPaper2}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/researchpaper3"}`}
            component={ResearchPaper3}
          />
          <link to={`${process.env.PUBLIC_URL + "/researchpaper1"}`} target="_blank">
                Open Paper 1
          </link>



          <Route
            path={`${process.env.PUBLIC_URL + "/cart"}`}
            component={Cart}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/checkout"}`}
            component={Checkout}
          />
          <Route exact component={NotFound} />
        </Switch>
      </ScrollToTop>
    </Router>
  );
}

export default App;
